import React from 'react';

import Str from '../../../helpers/Str';

import StatusBadge from '../../../components/badge/StatusBadge';

export interface TableColumn1<T = { [key: string]: any }> {
  title: string;
  field: keyof T;
  width?: number;
  columnType?: 'phone' | 'CNPJ' | 'CPF' | 'status' | 'reversedStatus' | 'YesOrNo';
}

export interface TableColumn2<T = { [key: string]: any }> {
  title: string;
  width?: number;
  render: (item: T) => React.ReactNode;
}

export interface BaseTableProps<T = { [key: string]: any }> {
  columns: (TableColumn1<T> | TableColumn2<T>)[];
  data?: T[];
  onItemClick?: (item: T) => void;
  loading?: boolean;
}

export default function BaseTable<T = { [key: string]: any }>({
  columns,
  data,
  onItemClick,
  loading,
}: BaseTableProps<T>) {
  function handleColumn(column: TableColumn1<T> | TableColumn2<T>, item: T) {
    if ((column as TableColumn2).render) {
      return (
        <td className="px-5" width={column.width}>
          {(column as TableColumn2).render(item as any)}
        </td>
      );
    }

    let val = (item as any)[(column as TableColumn1).field];

    const field = (column as TableColumn1).field;

    let validateRelationship: any[] = [];
    if (typeof field !== "number") {
      validateRelationship = field.split('.')
    }

    if (validateRelationship.length > 1){

      const fildOne = validateRelationship[0];
      const fildTwo = validateRelationship[1];
      val = (item as any)[fildOne][fildTwo] ? (item as any)[fildOne][fildTwo] : '';
      return (
        <td className="py-3 px-5" width={column.width}>
          {val}
        </td>
      );
    }

    if ((column as TableColumn1).columnType) {
      if ((column as TableColumn1).columnType === 'CNPJ') {
        return (
          <td className="py-3 px-5" width={column.width}>
            {Str.addCpfCnpjMask(val)}
          </td>
        );
      }

      if ((column as TableColumn1).columnType === 'phone') {
        return (
          <td className="py-3 px-5" width={column.width}>
            {Str.addPhoneMask(val)}
          </td>
        );
      }

      if ((column as TableColumn1).columnType === 'status') {
        return (
          <td className="py-3 px-5" width={column.width}>
            <StatusBadge status={val} />
          </td>
        );
      }

      if ((column as TableColumn1).columnType === 'reversedStatus') {
        return (
          <td className="py-3 px-5" width={column.width}>
            <StatusBadge status={!val} />
          </td>
        );
      }

      if ((column as TableColumn1).columnType === 'YesOrNo') {
        return (
          <td className="py-3 px-5" width={column.width}>
            <StatusBadge status={val} options={['Sim', 'Não']} />
          </td>
        );
      }
    }

    return (
      <td className="py-3 px-5" width={column.width}>
        {val}
      </td>
    );
  }

  return loading ? (
    <span className="flex items-center justify-center h-7">
      <svg className="animate-spin -ml-1 mr-3 h-[14px] w-[14px] text-black" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
      </svg>
    </span>
  ) : (
    <table className="w-full">
      <thead className="bg-light border-b">
        <tr>
          {columns.map((column, i) => (
            <th key={i} className="text-start py-3 px-5">
              {column.title}
            </th>
          ))}
        </tr>
      </thead>

      <tbody>
        {data?.map((item, i) => (
          <tr key={i} className={Str.tw(
            'border-t',
            onItemClick ? ' hover:bg-light-dark hover:cursor-pointer active:bg-primary transition' : ''
          )} onClick={() => onItemClick && onItemClick(item)}>
            {columns.map((column) => handleColumn(column, item))}
          </tr>
        ))}
      </tbody>
    </table>
  );
}
