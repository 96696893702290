import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import PageCard from '../../../components/card/PageCard';
import Input from '../../../components/input/Input';
import SearchButton from '../../../components/button/SearchButton';
import Button from '../../../components/button/Button'; 
import Form from '../../../components/form/Form';
import Str from '../../../helpers/Str';
import InputMask from 'react-input-mask';

export interface SearchPageCardProps {
  placeholder: string;
  left?: React.ReactNode;
  creationPath?: string;
  creationLabel?: string;
  fullWidthInput?: boolean;
  onSearch?: (q: string) => void;
}

export default function SearchPageCard({
  placeholder,
  creationPath,
  creationLabel,
  fullWidthInput,
  onSearch
}: SearchPageCardProps) {
  const navigate = useNavigate();
  const [q, setQ] = useState('');

  const handleOnChange = (data: any) => {
    if (!data.target.value.length){
      if (onSearch) {
        onSearch('');
      }
    }
    setQ(data.target.value);
  }
  return (
    <PageCard innerClassName="flex items-center gap-7">
      {creationPath && <Button 
        className="min-w-[147px]" 
        onClick={() => navigate(creationPath)}
      >
        {creationLabel}
      </Button>}
      <Form className="flex items-center gap-2 w-full" onSubmit={() => onSearch && onSearch(q)}>
        {/*<Input
          hideLabel
          placeholder={placeholder}
          className={fullWidthInput ? '' : 'max-w-[340px]'}
          value={q}
          setValue={setQ}
        />*/}
        <InputMask
          name={'search'}
          maskChar="_"
          type={'text'}
          placeholder={placeholder ? placeholder : ''}
          value={q ?? ''}
          onChange={handleOnChange}
          className={Str.tw(
            fullWidthInput ? '' : 'max-w-[340px]',
            'py-3',
            'bg-light border border-border rounded-md outline-none px-3 lg:px-6 w-full',
            'focus:bg-background focus:border-primary transition'
          )}
         mask={''}/>
        <SearchButton type="submit" />
      </Form>
    </PageCard>
  );
}
