import React, {useEffect, useState} from 'react';
import Alert from "../../../helpers/Alert";
import lojaApi from '../../../services/lojaApi';
import departamentoApi from '../../../services/departamentoApi';
import PageContainer from '../../../components/container/PageContainer';
import Title from '../../../components/text/Title';
import PageCard from '../../../components/card/PageCard';
import Select from '../../../components/select/Select';
import Input from '../../../components/input/Input';
import Button from '../../../components/button/Button';
import dashboardApi from '../../../services/dashboardApi';
import Str from '../../../helpers/Str';
import './gondolaPresence.css';
import localizacaoApi from '../../../services/localizacaoApi';

export default function GondolaPresence() {
  const [selectStore, setSelectStore] = useState([]);
  const [searchDataSelect, setSearchDataSelect] = useState<any>('');

  const [selectDepartamentos, setSelectDepartamentos] = useState<any>([]);
  const [searchDataDepartamento, setSearchDataDepartamento] = useState<any>(['Todos']);

  const [selectLocalizacao, setSelectLocalizacao] = useState<any>([]);
  const [searchDataLocalizacao, setSearchDataLocalizacao] = useState<any>(['Todos']);

  const [datesBetween, setDatesBetween] = useState<any>([]);

  const [searchData, setSearchData] = useState<any>({
    'data_inicio': new Date().toISOString().split("T")[0],
    'data_fim': new Date().toISOString().split("T")[0],
  });

  const [searchLoad, setSearchLoad] = useState(false);

  const [data, setData] = useState<any>([]);

  async function loadData() {
    Alert.await(`Carregando dados...`);
    const {data, isError} = await lojaApi.listAll();
    if (isError) return alert(data.message || 'Falha ao carregar dados da loja.');
    let dtSelect = data.map((res: any) => ({label:res.razao_social, key: res.id}));
    dtSelect.unshift({label:'Todos', key: ''});
    setSelectStore(dtSelect as any);

    /*const dataProd = await departamentoApi.listAll();
    if (dataProd.isError) return alert(dataProd.data.message || 'Falha ao carregar dados das seções.');
    let dtDepartamentoSelect = dataProd.data.map((res: any) => ({label:res.nome, key: res.nome}));
    dtDepartamentoSelect.unshift({label:'Todos', key: 'Todos'});
    setSelectDepartamentos(dtDepartamentoSelect as any);*/

    const dataLocalization = await localizacaoApi.listAll();
    if (dataLocalization.isError) return alert(dataLocalization.data.message || 'Falha ao carregar dadas localizações.');
    let dtlocSelect = dataLocalization.data.map((res: any) => ({label:res.descricao, key: res.descricao}));
    dtlocSelect.unshift({label:'Todos', key: 'Todos'});
    setSelectLocalizacao(dtlocSelect as any);
    Alert.close();

    Alert.close();
  }

  useEffect(() => {
    loadData();
  }, []);

  const handleClick = async () => {
    setSearchLoad(true);
    if (!searchData.data_inicio) {
      setSearchLoad(false)
      return Alert.error('É necessário selecionar a data de início');
    }
    if (!searchData.data_fim) {
      setSearchLoad(false)
      return Alert.error('É necessário selecionar a data final');
    }

    /*if (!searchDataLocalizacao.length) {
      setSearchLoad(false)
      return Alert.error('É necessário selecionar o departamento');
    }*/
    let dataLocalizacao = [...searchDataLocalizacao];

    var indice = dataLocalizacao.indexOf("Todos");

    if (!(indice < 0)){
      dataLocalizacao.splice(indice, 1);
    }

    let date1 = new Date(searchData.data_inicio);
    let date2= new Date(searchData.data_fim);
    date1.setDate(date1.getDate()+1);
    date2.setDate(date2.getDate()+1);
    const retorno = await dashboardApi.dashboardPresencaGondolas(
      searchDataSelect,
      searchData.data_inicio,
      searchData.data_fim,
      dataLocalizacao
    );

    setData(retorno.data);
    setDatesBetween(Str.getDatesBetween(date1, date2));
    setSearchLoad(false);
  }

  const totalGeral: number[] = [];

  return (
    <PageContainer menuTitle="Presença em gôndolas" pageTitle="Presença em gôndolas">
      <PageCard
        header={
          <div className="flex items-center justify-between">
            <Title color="primary">Presença em gôndolas</Title>
          </div>
        }
      >
        <div className="grid grid-cols-12 gap-5 pb-7">
          <Select
            label="Seleciona loja"
            className="col-span-3"
            value={searchDataSelect}
            setValue={v => setSearchDataSelect(v)}
            items={selectStore}
          />

          <Select
            multiple={true}
            label="Seleciona localização"
            className="col-span-2"
            value={searchDataLocalizacao}
            setValue={v => setSearchDataLocalizacao(v)}
            items={selectLocalizacao}
            disabled={!searchDataSelect}
          />

          <Input
            label="Data inicial"
            type={"date"}
            className="col-span-2"
            value={searchData.data_inicio}
            setValue={(v) => setSearchData({...searchData, ['data_inicio']: v})}
          />
          <Input
            label="Data final"
            type={"date"}
            className="col-span-2"
            value={searchData.data_fim}
            setValue={(v) => setSearchData({...searchData, ['data_fim']: v})}
          />

          <Button
            className="min-w-[147px] h-[37.5px] mt-[1.5rem]"
            onClick={() => handleClick()}
            loading={searchLoad}
          >
            Buscar dados
          </Button>
        </div>
        <div className={'flex-row'}>
          <div className={'pb-5 overflow-auto tableContainer'}>
            {
              data.length ?
                <table className="table-auto w-[100%] ">
                  <thead className="bg-light border-b sticky top-0 ">
                    <tr className={'border-t'}>
                      <th className={'text-start py-3 px-5'}>EAN</th>
                      <th className={'text-start py-3 px-5'}>Descrição</th>
                      <th className={'text-start py-3 px-5'}>Local</th>
                      {
                        datesBetween.map((date: any, key) => {
                          return(<th className={'text-start py-3 px-5'}>{date.substring(0, date.length - 5)}</th>);
                        })
                      }
                      <th className={'text-start py-3 px-5'}>Totais Geral</th>
                    </tr>
                  </thead>
                  <tbody>
                  {
                    data && data.map((dt: any) => {

                      let sum = 0;

                      dt.leituras.forEach(leitura => {
                        sum += parseInt(leitura.quantidade);
                      });
                      //console.log(dt);

                      return(
                        <>

                          <tr className={'border-t'}>
                            <td className={'text-start py-3 px-5'}>{dt.ean}</td>
                            <td  className={'text-start py-3 px-5'}>{dt.descricao}</td>
                            <td className={'text-start py-3 px-5'}>{dt.local}</td>
                            {
                              datesBetween.map((date, key) => {
                                const leitura = dt.leituras.find(leitura => date === Str.convertDateCreatedAt(leitura.data));
                                //console.log(leitura);
                                totalGeral[key] = totalGeral[key] || 0;
                                if (leitura) {
                                  totalGeral[key] += parseInt(leitura.quantidade);
                                  return(<td className={'text-start py-3 px-5'}>{parseInt(leitura.quantidade)}</td>);
                                }else{
                                  return(<td className={'text-start py-3 px-5'}>0</td>);
                                }
                              })
                            }
                            <td className={'text-start py-3 px-5'}>{sum}</td>
                          </tr>
                          {/*<tr className={'border-t'}>
                            <td className={'text-start py-3 px-5'}>{dt.ean}</td>
                            <td  className={'text-start py-3 px-5'}>{dt.descricao}</td>
                            <td className={'text-start py-3 px-5'}>{dt.local}</td>
                            {
                              datesBetween.map((date, key) => {
                                const leitura = dt.leituras.find(leitura => date === Str.convertDateCreatedAt(leitura.data));
                                //console.log(leitura);
                                totalGeral[key] = totalGeral[key] || 0;
                                if (leitura) {
                                  totalGeral[key] += parseInt(leitura.quantidade);
                                  return(<td className={'text-start py-3 px-5'}>{parseInt(leitura.quantidade)}</td>);
                                }else{
                                  return(<td className={'text-start py-3 px-5'}>0</td>);
                                }
                              })
                            }
                            <td className={'text-start py-3 px-5'}>{sum}</td>
                          </tr>*/}
                        </>
                        /*<tr className={'border-t'}>
                          <td className={'text-start py-3 px-5'}>{dt.ean}</td>
                          <td  className={'text-start py-3 px-5'}>{dt.descricao}</td>
                          <td className={'text-start py-3 px-5'}>{dt.local}</td>
                          {
                            datesBetween.map((date, key) => {
                              const leitura = dt.leituras.find(leitura => date === Str.convertDateCreatedAt(leitura.data));
                              //console.log(leitura);
                              totalGeral[key] = totalGeral[key] || 0;
                              if (leitura) {
                                totalGeral[key] += parseInt(leitura.quantidade);
                                return(<td className={'text-start py-3 px-5'}>{parseInt(leitura.quantidade)}</td>);
                              }else{
                                return(<td className={'text-start py-3 px-5'}>0</td>);
                              }
                            })
                          }
                          <td className={'text-start py-3 px-5'}>{sum}</td>
                        </tr>*/
                      )
                    })
                  }
                  </tbody>
                </table>
                :
                ''
            }
          </div>
        </div>
      </PageCard>
    </PageContainer>
  );

}