import React from 'react';

import produtoApi from '../../../services/produtoApi';

import ListPageLayout from '../../../components/layout/ListPageLayout';
import IconButton from '../../../components/button/IconButton';
import {BiPencil, BiTrash} from 'react-icons/bi';
import {AiOutlineQrcode} from 'react-icons/ai';
import {useNavigate} from 'react-router-dom';

export default function ProductsBase() {
  const navigate = useNavigate();
  return <ListPageLayout 
    menuTitle="Base de Produtos"
    searchPlaceholder="Buscar Produtos"
    creationEndpoint="/cadastros/base-produtos/cadastro"
    creationLabel="Cadastrar Produto"
    //loader={produtoApi.all}
    loader={produtoApi.getAllWith}
    columns={[
      { title: 'Descrição', field: 'descricao' },
      { title: 'Marca', field: 'marca' },
      { title: 'Departamento', field: 'r_departamento.nome' },
      { title: 'Seção', field: 'r_secao.nome' },
      { title: 'Possui Validade?', field: 'validade', columnType: 'YesOrNo' },
    ]}
  />;
}
