import React, {useEffect, useState} from 'react';
import {Localizacao, Loja, TipoLeitura, Usuario} from '../../../types';
import Input from '../../../components/input/Input';
import Select from '../../../components/select/Select';
import RegisterPageLayout from '../../../components/layout/RegisterPageLayout';
import Button from '../../../components/button/Button';
import lojaApi from '../../../services/lojaApi';
import usuarioApi from '../../../services/usuarioApi';
import Table from '../../../components/table/Table';
import IconButton from '../../../components/button/IconButton';
import {BiArrowBack, BiPencil, BiTrash} from 'react-icons/bi';
import Checkbox from '../../../components/checkbox/Checkbox';
import useTable from '../../../hooks/useTable';
import PageContainer from '../../../components/container/PageContainer';
import useForm from '../../../hooks/useForm';
import Title from '../../../components/text/Title';
import PageCard from '../../../components/card/PageCard';
import {useNavigate, useParams} from 'react-router-dom';
import Alert from '../../../helpers/Alert';
import tipoLeituraApi from '../../../services/tipoLeituraApi';
import roleApi from '../../../services/roleApi';

export default function UserRegistration() {
  const params = useParams();
  const id = params.id;
  const navigate = useNavigate();
  const [load, setLoad] = useState(false);
  const [loadInfo, setLoadInfo] = useState(false);

  const [rolesOptions, setRolesOptions] = useState([])

  const initialState = {
    nome: '',
    password: '',
    email: '',
    cpf: '',
    telefone: '',
    bloqueado: false,
    appUser: true,
    site_user: true,
  };

  const [state, setState] = useState<Usuario>(initialState);
  const [lojasCheck, setLojasCheck] = useState<any>([]);
  const [pesquisasCheck, setPesquisasCheck] = useState<any>([]);

  const { tableProps: lojaTableProps, loadData } = useTable<Loja>({
    denyInitialLoad: true,
    dataLoader: async (params) => {
      const { data, isError } = await lojaApi.site(params);
      return isError ? false : data;
    }
  });

  const { tableProps, onSearch } = useTable<TipoLeitura>({
    dataLoader: async (params) => {
      const { data, isError } = await tipoLeituraApi.all(params);
      return isError ? false : data;
    }
  });

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    setLoadInfo(true);
    loadInfos()
    if(id){
      setLoad(true);
      loadUserData();
    }
  }, [id]);

  async function loadUserData() {
    if (! params.id) return;
    Alert.await('Carregando dados...');
    const {data, isError} = await usuarioApi.one(params.id);
    let dtlojas = [];
    dtlojas = data.lojas && data.lojas.map((res) => {
      return res.id.toString();
    });
    setLojasCheck(dtlojas);

    let dtTipoLeituras = [];
    dtTipoLeituras = data.tipos_leitura && data.tipos_leitura.map((res) => {
      return res.id.toString();
    });
    setPesquisasCheck(dtTipoLeituras);

    if (data.roles.length){
      data.role = data.roles[data.roles.length-1].id;
    }

    if (isError) return alert(data.message || 'Falha ao carregar dados.');
    setState(data);
    setLoad(false);
    Alert.close();
  }
  function onCkeckLojas(l){
    if (lojasCheck.includes(l.value.toString())){
      setLojasCheck((lojasCheck) =>
        lojasCheck.filter((id) => id !== l.value)
      );
    }else{
      setLojasCheck([...lojasCheck, l.value]);
    }
  }

  function onCkeckPesquisas(p) {
    if (pesquisasCheck.includes(p.value.toString())){
      setPesquisasCheck((pesquisasCheck) =>
        pesquisasCheck.filter((id) => id !== p.value)
      );
    }else{
      setPesquisasCheck([...pesquisasCheck, p.value]);
    }
  }

  const handleChange = (value, input) => {
    setState({...state, [input]: value});
  };

  const onSelect = (e, input) => {
    //setState({...state, [input]: e});
  };

  const handleSubmit = () => {
    setLoad(true);
    if (id){
      update();
    }else{
      save();
    }
  };

  async function save(){
    const dt = state;
    dt.password = '123456';
    dt.lojas = lojasCheck;
    dt.tipos_leitura = pesquisasCheck;
    const { data, isError } = await usuarioApi.create(state);
    setLoad(false);

    if (isError) return Alert.error(data);
    Alert.success('Usuário salvo com sucesso');
    navigate('/usuarios/lista');
  }

  async function update() {
    if (state.id) {
      const dt = state;
      dt.lojas = lojasCheck;
      dt.tipos_leitura = pesquisasCheck;
      const { data, isError } = await usuarioApi.update(state.id.toString(), dt);
      setLoad(false);
      if (isError) return Alert.error(data);
      Alert.success('Usuário editado com sucesso');
    }
  }

  async function resetPassword() {
    if (state.id) {
      const formData = {id: id}
      const {data, isError} = await usuarioApi.resetPassword(formData);
      setLoad(false);
      if (isError) return Alert.error(data);
      Alert.success('Foi enviado um e-mail para o usuário');
    }
  }

  async function handleDelete() {
    if (state.id) {
      const formData = {id: id}
      const {data, isError} = await usuarioApi.destroy(state.id.toString());
      setLoad(false);
      if (isError) return Alert.error(data);
      Alert.success('Usuário deletado com sucesso.');
      navigate('/usuarios/lista');
    }
  }

  async function loadInfos() {
    Alert.await('Carregando dados...');
    const {data, isError} = await roleApi.listAll();
    const roleOpt = data.map((dt: any) => {
      return { label: dt.name, key:dt.id  }
    });
    setRolesOptions(roleOpt);
    Alert.close();
    setLoadInfo(false);
  }

  //SE USUARIO FOR USUARIO DE SITE ELE TEM PERFIL SE NÃO NÃO TEM
  //SE CASO APP MOSTRAR TABELAS

  return load || loadInfo ?
    <span className="flex items-center justify-center h-7">
      <svg className="animate-spin -ml-1 mr-3 h-[14px] w-[14px] text-black" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
      </svg>
    </span>
    :
    (
      <PageContainer menuTitle="Cadastro de Usuário">
        <PageCard
          header={
            <div className="flex items-center justify-between">
              <Title color="primary">Cadastro de Usuário</Title>
              <IconButton title="Voltar" icon={BiArrowBack} onClick={() => navigate('/usuarios/lista')} />
            </div>
          }
          footer={
            <div className="flex items-center gap-5">
              <Button loading={load} onClick={handleSubmit}>
                Salvar
              </Button>
              {state.id && <Button loading={load} onClick={handleDelete}>Excluir</Button>}
              <Button loading={load} onClick={() => {window.location.href = '/usuarios/cadastro';}}>Novo</Button>
            </div>
          }
        >
          <div className="grid grid-cols-10 gap-5">
            <Input
              label="Nome"
              name='nome'
              className="col-span-4"
              value={state.nome}
              setValue={v => handleChange(v, 'nome')}
            />
            <Input
              label="CPF"
              name='cpf'
              className="col-span-2"
              mask={'CPF'}
              inputType={'CPF'}
              value={state.cpf}
              setValue={v => handleChange(v, 'cpf')}
            />
            <Input
              label="E-mail"
              name='email'
              className="col-span-4"
              value={state.email}
              setValue={v => handleChange(v, 'email')}
            />
          </div>
          <div className="grid grid-cols-10 mt-5 gap-5">
            <Input
              label="Telefone"
              mask='cellphone'
              className="col-span-2"
              value={state.telefone}
              setValue={v => handleChange(v, 'telefone')}
            />
            <Select
              label="Status do cliente"
              name='bloqueado'
              className="col-span-2"
              value={state.bloqueado}
              setValue={v => handleChange(v, 'bloqueado')}
              items={[
                { key: false, label: 'Ativo'},
                { key: true, label: 'Bloqueado'},
              ]}
            />
            <Select
              label="Usuário do Portal"
              className="col-span-2"
              value={state.site_user}
              setValue={v => handleChange(v, 'site_user')}
              items={[
                { key: true, label: 'Sim'},
                { key: false, label: 'Não'},
              ]}
            />

            <Select
              label="Usuário do Aplicativo"
              className="col-span-2"
              value={state.appUser}
              setValue={v => handleChange(v, 'appUser')}
              items={[
                { key: true, label: 'Sim'},
                { key: false, label: 'Não'},
              ]}
            />

            {
              state.site_user ?
                <Select
                  label="Perfil do usuário"
                  name='role'
                  className="col-span-2"
                  value={state.role}
                  setValue={v => handleChange(v, 'role')}
                  items={rolesOptions}
                />
                :
                ''
            }


          </div>
          {/*<div className="grid grid-cols-10 mt-5 gap-5">
            {
              state.site_user ?
                <Select
                  label="Perfil do usuário"
                  name='role'
                  className="col-span-3"
                  value={state.role}
                  setValue={v => handleChange(v, 'role')}
                  items={rolesOptions}
                />
                :
                ''
            }
          </div>*/}
          {
            id ?
              <div className="grid grid-cols-10 mt-5 gap-5">
                <Button className={'bg-[#BAE6F2] text-[#61787F] px-[5rem] '} onClick={resetPassword} >
                  Resetar Senha
                </Button>
              </div>
              :
              ''
          }
          <div className={'mt-[2rem] p-5 border-t'}></div>
          {
            state.appUser ?
              <div className="grid grid-cols-2 gap-2">
                <div className="flex flex-col gap-2">
                  <Table<Loja> {...lojaTableProps} columns={[
                    { title: '', width: 20, render: (l) => (
                      <>
                        <div>
                          <Checkbox
                            name={'loja-' + l.id}
                            onValue={l.id}
                            value={lojasCheck.includes(l.id.toString())}
                            setValueTarget={onCkeckLojas}
                          />
                        </div>
                      </>
                    )},
                    { title: 'Lojas Vinculadas', field: 'razao_social' },
                    { title: 'Status', field: 'bloqueado', columnType: 'reversedStatus', width: 100 },
                  ]}/>
                </div>
                <div className="flex flex-col gap-2">
                  <Table {...tableProps} columns={[
                    { title: '', width: 20, render: (p) => (
                      <>
                        <div>
                          <Checkbox
                            name={'loja-' + p.id}
                            onValue={p.id}
                            value={pesquisasCheck.includes(p.id?.toString())}
                            setValueTarget={onCkeckPesquisas}
                          />
                        </div>
                      </>
                    )},
                    { title: 'Tipos de Pesquisa Vinculados', field: 'descricao' },
                    { title: 'Status', field: 'bloqueado', columnType: 'reversedStatus' },
                  ]} />
                </div>
              </div>
              :
              ''
          }
        </PageCard>
      </PageContainer>
    );
}
