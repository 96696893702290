import React, {useEffect, useState} from 'react';
import { FiChevronDown } from 'react-icons/fi';

import Logo2Src from '../../../assets/images/stockgoLogo.png';
import Title from '../../../components/text/Title';
import Dropdown from '../../../components/dropdown/Dropdown';
import Storage from '../../../helpers/Storage';
import useAppContext from '../../../hooks/useAppContext';
import useModal from '../../../hooks/useModal';

import Modal from '../../../components/modal/Modal';
import Form from '../../../components/form/Form';
import Input from '../../../components/input/Input';
import Button from '../../../components/button/Button';

import Alert from '../../../helpers/Alert';

import {changePassword} from '@/types';

import usuarioApi from '../../../services/usuarioApi';

export interface NavbarProps {
  pageTitle?: string;
}

export default function Navbar({
  pageTitle
}: NavbarProps) {
  const userData = Storage.getUserData();

  const appContext = useAppContext();

  const [dataPassword, setDataPassword] = useState<changePassword>(
    {
      atualPassword: '',
      novoPassword: '',
      repetirPassword: '',
    }
  );

  const { modalProps, open, close } = useModal();

  useEffect(() => {
    if (userData) {
      appContext.setCliente(userData.cliente);
      appContext.setUsuario(userData.usuario);
    }
  }, []);

  function handleLogout() {
    Storage.removeApiToken();
    Storage.removeUserData();
    window.location.href = '/entrar';
  }

  async function changePassword(e: any) {
    e.preventDefault();
    if (dataPassword.novoPassword != dataPassword.repetirPassword) {
      Alert.error('Senhas São diferentes');
      return;
    }
    //salva a senha
    const {data, isError} = await usuarioApi.changePassword(dataPassword);
    if (isError) return Alert.error(data);
    Alert.success('Senha alterada com sucesso!');
    close();
  }

  return (
    <>
      <nav className="bg-background border-b flex items-center">
        <aside className="py-4 w-[200px]">
          <img src={Logo2Src} className="w-[170px] pl-10" />
        </aside>
        <main className="mx-4 flex items-center justify-between flex-1">
          <Title size="lg">{pageTitle}</Title>
          <Dropdown
            trigger={<div className="flex items-center gap-2 hover:bg-light-dark py-2 px-3 rounded-md">
              <span className="flex flex-col items-start">
                <Title>{appContext.usuario?.nome}</Title>
                <small className="text-secondary">{appContext?.usuario?.email}</small>
              </span>

              <FiChevronDown />
            </div>}
            items={[
              { label: 'Alterar Senha', onClick: open },
              { label: 'Sair', onClick: handleLogout  }
            ]}
          />
        </main>
        <Modal size="lg" title="Alterar Senha" {...modalProps}>

          <Form onSubmit={changePassword} className="px-3 grid gap-5">
            <Input
              label="Senha"
              inputType="password"
              value={dataPassword.atualPassword}
              setValue={(atualPassword) => setDataPassword({ ...dataPassword, atualPassword })}
            />

            <Input
              label="Nova Senha"
              inputType="password"
              value={dataPassword.novoPassword}
              setValue={(novoPassword) => setDataPassword({ ...dataPassword, novoPassword })}
            />

            <Input
              label="Repetir Senha"
              inputType="password"
              value={dataPassword.repetirPassword}
              setValue={(repetirPassword) => setDataPassword({ ...dataPassword, repetirPassword })}
            />
          
            <Button type="submit" className="mt-5">
                Salvar
            </Button>
          </Form>
        </Modal>
      </nav>
    </>
  );
}
