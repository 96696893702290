import React from 'react';

import PageContainer from '../../components/container/PageContainer';

export default function Dashboards() {
  return (
    <PageContainer menuTitle="Dashboards">
      <h1>Dashboards</h1>
    </PageContainer>
  );
}
