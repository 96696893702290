import React from 'react';

import { AppRoute } from '.';

import Login from '../pages/Auth/Login';
import StoreRegistration from '../pages/Registrations/StoreRegistration';
import SurveyType from '../pages/Registrations/SurveyType';
import UserRegistration from '../pages/Users/UserRegistration';
import UserProfile from '../pages/Users/UserProfile';
import UserList from '../pages/Users/index';
import ProductsBase from '../pages/Registrations/ProductsBase';
import Registrations from '../pages/Registrations';
import Dashboards from '../pages/Dashboards';
import ValidityVision from '../pages/Dashboards/ValidityVision';
import ExposureVision from '../pages/Dashboards/ExposureVision';
import NumbersEvolution from '../pages/Dashboards/NumbersEvolution';
import StoreProduct from '../pages/Registrations/StoreProduct';
import Locations from '../pages/Registrations/Locations';
import UserResetSenha from '../pages/Users/UserResetSenha';
import ForgotPassord from '../pages/Auth/ForgotPassword';
import QuantityCount from '../pages/Dashboards/QuantityCount';
import LocalizationCount from '../pages/Dashboards/LocalizationCount';
import DueDate from '../pages/Dashboards/DueDate';
import ValidityBrigade from '../pages/Dashboards/ValidityBrigade';
import GondolaPresence from '../pages/Dashboards/GondolaPresence';
import PeriodBrigade from '../pages/Dashboards/PeriodBrigade';
import ProductLaunch from '../pages/Dashboards/productLaunch';
import Searches from '../pages//Maintenance/Searches';

const appRoutes: AppRoute[] =  [
  {
    path: '/',
    requiresAuth: true,
    element: <Dashboards /> 
  },
  {
    path: '/dashboards/contagem-quantidade',
    requiresAuth: true,
    element: <QuantityCount />
  },
  {
    path: '/dashboards/contagem-local',
    requiresAuth: true,
    element: <LocalizationCount />
  },
  {
    path: '/dashboards/vencimento',
    requiresAuth: true,
    element: <DueDate />
  },{
    path: '/dashboards/brigada-validade',
    requiresAuth: true,
    element: <ValidityBrigade />
  },
  {
    path: '/dashboards/visao-validade',
    requiresAuth: true,
    element: <ValidityVision /> 
  },
  {
    path: '/dashboards/visao-exposicao',
    requiresAuth: true,
    element: <ExposureVision /> 
  },
  {
    path: '/dashboards/evolucao-numeros',
    requiresAuth: true,
    element: <NumbersEvolution /> 
  },
  {
    path: '/dashboards/presenca-gondolas',
    requiresAuth: true,
    element: <GondolaPresence />
  },
  {
    path: '/dashboards/brigada-periodo',
    requiresAuth: true,
    element: <PeriodBrigade />
  },
  /*{
    path: '/dashboards/lancamentos',
    requiresAuth: true,
    element: <ProductLaunch />
  },*/
  {
    path: '/entrar',
    requiresAuth: false,
    element: <Login /> 
  },
  {
    path: '/recuperar/senha',
    requiresAuth: false,
    element: <ForgotPassord />
  },
  {
    path: '/cadastros/lojas',
    requiresAuth: true,
    element: <Registrations /> 
  },
  {
    path: '/cadastros/cadastro-lojas/:id',
    requiresAuth: true,
    element: <StoreRegistration /> 
  },
  {
    path: '/cadastros/cadastro-lojas',
    requiresAuth: true,
    element: <StoreRegistration /> 
  },
  {
    path: '/cadastros/base-produtos',
    requiresAuth: true,
    element: <ProductsBase /> 
  },
  {
    path: '/cadastros/base-produtos/cadastro',
    requiresAuth: true,
    element: <StoreProduct /> 
  },
  {
    path: '/cadastros/base-produtos/cadastro/:id',
    requiresAuth: true,
    element: <StoreProduct /> 
  },
  {
    path: '/cadastros/localizacoes',
    requiresAuth: true,
    element: <Locations /> 
  },
  {
    path: '/cadastros/tipo-pesquisa',
    requiresAuth: true,
    element: <SurveyType /> 
  },
  {
    path: '/usuarios/lista',
    requiresAuth: true,
    element: <UserList />
  },
  {
    path: '/usuarios/cadastro',
    requiresAuth: true,
    element: <UserRegistration /> 
  },
  {
    path: '/usuarios/cadastro/:id',
    requiresAuth: true,
    element: <UserRegistration />
  },
  {
    path: '/usuarios/perfil',
    requiresAuth: true,
    element: <UserProfile />
  },
  {
    path: '/usuarios/reset-senha/:hash',
    requiresAuth: false,
    element: <UserResetSenha />
  },
  {
    path: '/manutencao/pesquisas',
    requiresAuth: true,
    element: <Searches />
  },
  {
    path: '/*',
    requiresAuth: false,
    element: <h1>404</h1>
  }
];

export default appRoutes;
