import React, {useEffect, useState} from 'react';
import PageContainer from '../../../components/container/PageContainer';
import SearchPageCard from '../../../components/card/SearchPageCard';
import Table from '../../../components/table/Table';
import PageCard from '../../../components/card/PageCard';
import Title from '../../../components/text/Title';
import Input from '../../../components/input/Input';
import useTable from '../../../hooks/useTable';
import Button from '../../../components/button/Button';
import Checkbox from '../../../components/checkbox/Checkbox';
import {PerfilUsuario, Role} from '../../../types';
import Alert from "../../../helpers/Alert";
import permissionApi from "../../../services/permissionApi";
import roleApi from "../../../services/roleApi";


export default function UserProfile() {
  const [checkOptions, setCheckOptions] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);

  const [state, setState] = useState('');
  const [stateId, setStateId] = useState('');

  const [accessPermissions, setAccessPermissions] = useState<any[]>([]);
  const [dataPermissions, setDataPermissions] = useState<any[]>([]);
  //const [accessPermissions, setAccessPermissions] = useState<PerfilUsuario>(initialState);

  async function loadInicialData() {
    Alert.await(`Carregando dados...`);
    setLoading(true);
    const { data, isError } = await permissionApi.listAll();
    let newData: any = [];
    Object.keys(data).map((key, index) =>{
      const permission = data[key];
      const dt: any = {
        'label': key,
        'value': key,
        'nome': key,
        'checked': true,
        'sonCheckeds': []
      }
      permission.map((pr?: any) => {
        dt.sonCheckeds.push({
          'label': pr.nome,
          'nome': pr.code,
          'value': pr.id,
          'checked': true,
        })
      })
      newData.push(dt);
    });
    setDataPermissions(newData)
    Alert.close();
    setLoading(false);
  }

  useEffect(() => {
    loadInicialData()
  }, []);

  function onCkeck(e: any) {
    let newCheck = [...dataPermissions];
    const checkSplit = e.name.split('-');
    if (checkSplit.length > 1){
      const key = newCheck.findIndex(nc => nc.label === checkSplit[0]);
      const keySonChecked = newCheck[key]['sonCheckeds'].findIndex((nc: any) => nc.nome === checkSplit[1]);
      newCheck[key]['sonCheckeds'][keySonChecked].checked = e.checked
      setAccessPermissions(newCheck);
    }else{
      const key = newCheck.findIndex(nc => nc.label === e.name);
      newCheck[key].checked = e.checked;
      newCheck[key]['sonCheckeds'].map((nc: any) => {
        nc.checked = e.checked;
        return nc;
      })
      setAccessPermissions(newCheck);
    }
  }

  function onSubmit (){
    save();
  }

  async function save(){
    let permissionsId: any[] = [];
    dataPermissions.map((dp: any) => {
      dp.sonCheckeds.map((sc: any) => {
        if (sc.checked){
          permissionsId.push(sc.value);
        }
      })
    });

    const finalData = {
      'name': state,
      "permissions": permissionsId
    }
    const { data, isError } = stateId ? await roleApi.update(stateId, finalData) : await roleApi.create(finalData);
    if (isError) return Alert.error(data);
    Alert.success('Perfil de usuário salvo com sucesso');
    setTimeout(() => {
      setState('');
      loadInicialData();
      loadData();
      Alert.close();
    }, 2000);

  }

  const openData = (dt: any) => {
    setState(dt.name);
    setStateId(dt.id);
    const permissionsUser = dt['permissions'];
    let check = [...dataPermissions];
    check.map((ch: any, index: any) => {
      check[index]['checked'] = false;
      check[index]['sonCheckeds']?.map((sc: any) => {
        sc.checked = false;
      });
    });
    permissionsUser.map((pu: any, indexPerUser: any) => {
      check.map((ch: any, index: any) => {
        const sonsCheckList = ch.sonCheckeds;
        const key = sonsCheckList.findIndex((sc: any) => sc.value === pu.id);
        if (key >= 0){
          check[index]['sonCheckeds'][key].checked = true;
        }
      });
    });
  }

  const { tableProps, onSearch, loadData } = useTable<Role>({
    dataLoader: async (params) => {
      const { data, isError } = await roleApi.all(params);
      return isError ? false : data;
    },
    onItemClick: openData,
  });

  return loading ? (
    <span className="flex items-center justify-center h-7">
      <svg className="animate-spin -ml-1 mr-3 h-[14px] w-[14px] text-black" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
      </svg>
    </span>
  ) : (
    <PageContainer menuTitle="Perfil de Usuário">
      <div className="grid grid-cols-3 gap-2">
        <div className="flex flex-col gap-2">
          <SearchPageCard
            fullWidthInput
            onSearch={onSearch}
            placeholder="Buscar perfil de usuário"
          />
          <Table
            {...tableProps}
            columns={[
              { title: 'Id', field: 'id' },
              { title: 'Nome', field: 'name' },
            ]}
          />
        </div>

        <PageCard
          removeContentPadding
          className="col-span-2"
          header={<Title color="primary">Cadastro de Perfil e Permissões</Title>}
          footer={<div className="flex items-center gap-5">
            <Button
              onClick={onSubmit}
              loading={loading}
            >
              Salvar
            </Button>
          </div>}
        >
          <header className="grid grid-cols-5 gap-5 border-b p-5 pb-7">
            <Input
              disabled
              label="Id"
              value={stateId}
            />
            <Input
              label="Perfil"
              value={state}
              setValue={e => setState(e)}
              className="col-span-3"
            />
          </header>

          <main className="p-5">
            <span>Permissões de acesso</span>
            <div className="border rounded-md mt-2 p-5 grid gap-5">
              {
                dataPermissions.length && dataPermissions.map((dp: any) => {
                  return(
                    <div>
                      {dp.label}
                      {/*<Checkbox
                        setValueTarget={onCkeck}
                        name={dp.nome}
                        label={dp.label}
                        value={dp.checked}
                      />*/}
                      <div className="ml-[2rem] p-2">
                        {
                          dp.sonCheckeds.length && dp.sonCheckeds.map((sc: any) => {
                            return(
                              <Checkbox
                                setValueTarget={onCkeck}
                                name={`${dp.nome}-${sc.nome}`}
                                className="pt-4"
                                label={sc.label}
                                value={sc.checked}
                              />
                            )
                          })
                        }
                      </div>
                    </div>
                  )
                })
              }
            </div>
          </main>
        </PageCard>
      </div>
    </PageContainer>
  );
}