/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';

import PageContainer from '../../../components/container/PageContainer';
import PageCard from '../../../components/card/PageCard';
import Title from '../../../components/text/Title';
import Button from '../../../components/button/Button';
import { useNavigate, useParams } from 'react-router-dom';
import Alert from '../../../helpers/Alert';
import { RequestResponse } from '../../../services/api';
import { RequestError } from '../../../services/api';
import useForm from '../../../hooks/useForm';
import Str from '../../../helpers/Str';
import Form from '../../../components/form/Form';
import IconButton from '../../../components/button/IconButton';
import { BiArrowBack } from 'react-icons/bi';

export interface RegisterPageLayoutProps<T> {
  menuTitle?: string;
  className?: string;
  returnPath?: string;
  resourceName?: string;
  finishPath?: string;
  initialData?: Partial<Partial<T>>;
  loader?: (id: string) => Promise<RequestError<any> | RequestResponse<T>>;
  destroyHandler?: (id: string) => Promise<RequestError<any> | RequestResponse<any>>;
  createHandler?: (formData: Partial<T>) => Promise<RequestError<any> | RequestResponse<T>>;
  updateHandler?:  (id: string, formData: Partial<T>) => Promise<RequestError<any> | RequestResponse<T>>;
  formContent: (form: Partial<T>, handleChange: (value: string | number | boolean, name: keyof T) => void, id?: any, load?: boolean) => React.ReactNode;
  newPath?: string;
}

export default function RegisterPageLayout<T>({
  menuTitle,
  finishPath,
  returnPath,
  resourceName,
  loader,
  destroyHandler,
  createHandler,
  updateHandler,
  formContent,
  initialData,
  className,
  newPath
}: RegisterPageLayoutProps<T>) {
  const params = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const { form, setForm, handleChange } = useForm<Partial<T>>({ initialData });

  async function loadData() {
    if (! params.id) return;
    setIsLoading(true);
    Alert.await(`Carregando dados da ${resourceName?.toLowerCase()}...`);
    if (loader) {
      const {data, isError} = await loader(params.id);
      if (isError) return alert(data.message || 'Falha ao carregar dados.');
      setForm(data);
      setIsLoading(false);
      Alert.close();
    }
  }

  async function handleSubmit() {
    setIsLoading(true);
    if (updateHandler && createHandler){
      const { data, isError } = params.id
        ? await updateHandler(params.id, form)
        : await createHandler(form);
      setIsLoading(false);
      if (isError){
        if (typeof data === 'object') {
          Object.keys(data).forEach(function(key, index) {
            Alert.error(data[key][0]);
          });
          return;
        }
        return Alert.error(data.message? data.message : data);
      }
      if (finishPath) navigate(finishPath);
    }
  }

  async function handleDelete() {
    if (destroyHandler){
      if (! params.id) return;
      const confirm = await Alert.confirm(
        'Tem certeza que deseja excluir?',
        `Excluir ${resourceName?.toLowerCase()}?`
      );
      if (! confirm) return;
      setIsLoading(true);
      const { data, isError } = await destroyHandler(params.id);
      setIsLoading(false);
      if (isError) return alert(data.message);
      if (finishPath) navigate(finishPath);
    }
  }

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    setForm({ ...form, ...initialData });
  }, [initialData]);

  return (
    <PageContainer className={className} pageTitle={`Cadastro de ${Str.firstToUpperCase(resourceName)}`} menuTitle={menuTitle}>
      <PageCard 
        header={<div className="flex items-center justify-between">
          <Title color="primary">{`Cadastro de ${Str.firstToUpperCase(resourceName)}`}</Title>
          {returnPath && <IconButton title="Voltar" icon={BiArrowBack} onClick={() => navigate(returnPath)} />}
        </div>}
        footer={<div className="flex items-center gap-5">
          {params.id && <Button loading={isLoading} onClick={() => {
            if (!finishPath) return;
            if (typeof newPath === 'string') {
              window.location.href = newPath;
            }
          }}>Novo</Button>}
          {params.id && <Button loading={isLoading} onClick={handleDelete}>Excluir</Button>}
          <Button loading={isLoading} onClick={handleSubmit}>
            Salvar
          </Button>
        </div>}
      >
        <Form onSubmit={handleSubmit}>
          {formContent(form, handleChange, params.id, isLoading)}
          <button hidden disabled={isLoading} onClick={handleSubmit}></button> {/* Está aqui apenas para que o usuário consiga fazer o submit do formulário com Enter */}
        </Form>
      </PageCard>
    </PageContainer>
  );
}
