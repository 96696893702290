import React, { useEffect, useState } from 'react';

import { Loja } from '../../../types';
import lojaApi from '../../../services/lojaApi';

import useAppContext from '../../../hooks/useAppContext';

import RegisterPageLayout from '../../../components/layout/RegisterPageLayout';
import Input from '../../../components/input/Input';
import Select from '../../../components/select/Select';

export default function StoreRegistration() {
  const appContext = useAppContext();
  const [initialData, setInitialData] = useState<Partial<Loja>>({
    blocked: false,
  });

  useEffect(() => {
    setInitialData({ ...initialData, cliente_id: appContext.cliente?.id});
  }, [appContext.cliente?.id]);

  return (
    <RegisterPageLayout<Loja>
      resourceName="loja"
      menuTitle="Cadastro de Lojas"
      finishPath="/cadastros/lojas"
      newPath="/cadastros/cadastro-lojas"
      returnPath="/cadastros/lojas"
      loader={lojaApi.one}
      destroyHandler={lojaApi.destroy}
      createHandler={lojaApi.create}
      updateHandler={lojaApi.update}
      initialData={initialData}
      formContent={(form, handleChange) => <>
        <div className="border-b grid grid-cols-12 gap-5 pb-7">
          <Input
            label="CNPJ *"
            inputType="CNPJ"
            className="col-span-9"
            value={form.cnpj}
            setValue={v => handleChange(v, 'cnpj')}
          />
            
          <Select
            label="Status do Cliente"
            className="col-span-3"
            value={form.blocked}
            setValue={v => handleChange(v, 'blocked')}
            items={[
              { label: 'Ativo', key: false },
              { label: 'Inativo', key: true },
            ]}
          />

          <Input
            label="Razão Social *"
            className="col-span-6"
            value={form.razao_social}
            setValue={v => handleChange(v, 'razao_social')}
          />

          <Input
            label="Nome Fantasia *"
            className="col-span-6"
            value={form.nome_fantasia}
            setValue={v => handleChange(v, 'nome_fantasia')}
          />
        </div>

        <div className="border-b grid grid-cols-11 gap-5 py-7">
          <Input
            label="Responsável *"
            className="col-span-5"
            value={form.responsavel}
            setValue={v => handleChange(v, 'responsavel')}
          />

          <Input
            label="Telefone Principal *"
            mask="cellphone"
            className="col-span-2"
            value={form.telefone_principal}
            setValue={v => handleChange(v, 'telefone_principal')}
          />
            
          <Input
            label="Telefone Celular"
            mask="cellphone"
            className="col-span-2"
            value={form.telefone_celular}
            setValue={v => handleChange(v, 'telefone_celular')}
          />

          <Input
            label="Telefone Fixo"
            mask="phone"
            className="col-span-2"
            value={form.telefone_fixo}
            setValue={v => handleChange(v, 'telefone_fixo')}
          />

          <Input
            label="E-mail *"
            className="col-span-5"
            value={form.email}
            setValue={v => handleChange(v, 'email')}
          />
        </div>

        <div className="grid grid-cols-12 gap-5 py-7">
          <Input
            label="Logradouro *"
            className="col-span-9"
            value={form.logradouro}
            setValue={v => handleChange(v, 'logradouro')}
          />

          <Input
            label="Número *"
            type="number"
            min={0}
            className="col-span-1"
            value={form.numero}
            setValue={v => handleChange(v, 'numero')}
          />

          <Input
            label="Quadra"
            className="col-span-1"
            value={form.quadra}
            setValue={v => handleChange(v, 'quadra')}
          />

          <Input
            label="Lote"
            className="col-span-1"
            value={form.lote}
            setValue={v => handleChange(v, 'lote')}
          />

          <Input
            label="Complemento"
            className="col-span-3"
            value={form.complemento}
            setValue={v => handleChange(v, 'complemento')}
          />

          <Input
            label="CEP *"
            inputType="CEP"
            className="col-span-2"
            value={form.cep}
            setValue={v => handleChange(v, 'cep')}
          />

          <Input
            label="Setor *"
            className="col-span-3"
            value={form.setor}
            setValue={v => handleChange(v, 'setor')}
          />

          <Input
            label="Cidade *"
            className="col-span-3"
            value={form.cidade}
            setValue={v => handleChange(v, 'cidade')}
          />

          <Input
            label="UF *"
            inputType="UF"
            className="col-span-1"
            value={form.UF}
            setValue={v => handleChange(v, 'UF')}
          />

          <Input
            label="Longitude"
            className="col-span-2"
            value={form.longitude}
            setValue={v => handleChange(v, 'longitude')}
          />

          <Input
            label="Latitude"
            className="col-span-2"
            value={form.latitude}
            setValue={v => handleChange(v, 'latitude')}
          />
        </div>
      </>}
    />
  );
}
