import React from 'react';

import { PaginatedData } from '@/types';

import Str from '../../../helpers/Str';

import Pagination from '../../../components/nav/Pagination';
import Select from '../../../components/select/Select';
import BaseTable from '../BaseTable';

export interface TableColumn1<T = { [key: string]: any }> {
  title: string;
  field: keyof T;
  width?: number;
  columnType?: 'phone' | 'CNPJ' | 'CPF' | 'status' | 'reversedStatus' | 'YesOrNo';
}

export interface TableColumn2<T = { [key: string]: any }> {
  title: string;
  width?: number;
  render: (item: T) => React.ReactNode;
}

export interface TableProps<T = { [key: string]: any }> {
  className?: string;
  columns: (TableColumn1<T> | TableColumn2<T>)[];
  data?: PaginatedData<T>;
  onPageChange?: (page: number) => void;
  onPerPageChange?: (perPage: number) => void;
  onItemClick?: (item: T) => void;
  loading?: boolean;
}

export default function Table<T = { [key: string]: any }>({
  className,
  columns,
  data,
  onPageChange,
  onPerPageChange,
  onItemClick,
  loading,
}: TableProps<T>) {
  return loading ? (
    <span className="flex items-center justify-center h-7">
      <svg className="animate-spin -ml-1 mr-3 h-[14px] w-[14px] text-black" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
      </svg>
    </span>
  ) : (
    <div className={Str.tw(className, 'border rounded-lg bg-background')}>
      <BaseTable 
        columns={columns}
        data={data?.data}
        onItemClick={onItemClick}
      />

      <footer className="py-3 px-5 border-t flex items-center justify-between flex-wrap gap-5">
        <div className="flex items-center gap-3">
          <Select<number>
            value={15}
            setValue={onPerPageChange}
            items={[
              { key: 15, label: 15 },
              { key: 25, label: 25 },
              { key: 50, label: 50 },
              { key: 100, label: 100 },
            ]} 
          />
          {(!!data?.from && !!data?.to) && <span className="text-slate-400">Exibindo de {data?.from} até {data?.to} de {data?.total}</span>}
        </div>

        <div>
          {!!data && <Pagination 
            currentPage={data.current_page}
            lastPage={data.last_page}
            onPageChange={onPageChange}
          />}
        </div>
      </footer>
    </div>
  );
}
