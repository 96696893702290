import { QueryParams, Produto } from '../types';
import api, { getAll, create, update, getOne, deleteOne } from './api';

const endpoint = 'produtos';

export default {
  all: async (params?: QueryParams) => getAll<Produto>({ endpoint, params }),
  one: async (id: string) => getOne<Produto>({ endpoint, id }),
  destroy: async (id: string) => deleteOne({ endpoint, id }),
  create: async (formData: Partial<Produto>) => create<Produto>({ endpoint, formData }),
  update: async (id: string, formData: Partial<Produto>) => update<Produto>({ endpoint, formData, id }),
  async getSecao(){
    try {
      const { data } = await api.get(`produtos/sessoes`);
      return { data, isError: false };
    } catch (err: any) {
      console.log(err);
      return {
        data: err?.response?.data ?? { message: 'Falha ao processar requisição' },
        isError: true
      };
    }
  },

  async getDepartamentos(){
    try {
      const { data } = await api.get(`produtos/departamentos`);
      return { data, isError: false };
    } catch (err: any) {
      console.log(err);
      return {
        data: err?.response?.data ?? { message: 'Falha ao processar requisição' },
        isError: true
      };
    }
  },
  async getAllWith(params?: QueryParams){
    try {
      const page    = params?.page    ? 'page='    + params?.page    : 'page=1';
      const perPage = params?.perPage ? '&perpage=' + params?.perPage : '';
      const search  = params?.search  ? '&search='  + params?.search  : '';
      const { data } = await api.get(`${endpoint}?${page}${perPage}${search}&relations=rDepartamento,rSecao`);

      return { data, isError: false };
    } catch (err: any) {
      console.log(err);
      return {
        data: err?.response?.data ?? { message: 'Falha ao processar requisição' },
        isError: true
      };
    }
  },
};
