import PageContainer from '../../../components/container/PageContainer';
import React, {useEffect, useState} from 'react';
import Title from '../../../components/text/Title';
import PageCard from '../../../components/card/PageCard';
import Alert from '../../../helpers/Alert';
import lojaApi from '../../../services/lojaApi';
import localizacaoApi from '../../../services/localizacaoApi';
import Select from '../../../components/select/Select';
import Input from '../../../components/input/Input';
import Button from '../../../components/button/Button';
import dashboardApi from '../../../services/dashboardApi';
import Str from '../../../helpers/Str';
import api from '../../../services/api';
import { saveAs } from 'file-saver';


export default function PeriodBrigade() {
  const [selectStore, setSelectStore] = useState([]);
  const [searchDataSelect, setSearchDataSelect] = useState<any>('');
  const [selectLocalizacao, setSelectLocalizacao] = useState<any>([]);
  const [searchDataLocalizacao, setSearchDataLocalizacao] = useState<any>(['Todos']);
  const [datesBetween, setDatesBetween] = useState<any>([]);
  const date = new Date();
  const [searchData, setSearchData] = useState<any>({
    'data_inicio': new Date(date.getFullYear(), date.getMonth(), 1).toISOString().split("T")[0],
    'data_fim': new Date(date.getFullYear(), date.getMonth() + 1, 0).toISOString().split("T")[0],
  });
  const [searchLoad, setSearchLoad] = useState(false);
  const [data, setData] = useState<any>([]);
  async function loadData() {
    Alert.await(`Carregando dados...`);
    const {data, isError} = await lojaApi.listAll();
    if (isError) return alert(data.message || 'Falha ao carregar dados da loja.');
    let dtSelect = data.map((res: any) => ({label:res.razao_social, key: res.id}));
    dtSelect.unshift({label:'Todos', key: ''});
    setSelectStore(dtSelect as any);
    const dataLocalization = await localizacaoApi.listAll();
    if (dataLocalization.isError) return alert(dataLocalization.data.message || 'Falha ao carregar dadas localizações.');
    let dtlocSelect = dataLocalization.data.map((res: any) => ({label:res.descricao, key: res.descricao}));
    dtlocSelect.unshift({label:'Todos', key: 'Todos'});
    setSelectLocalizacao(dtlocSelect as any);
    Alert.close();
  }

  useEffect(() => {
    loadData();
  }, []);

  const handleClick = async () => {
    setSearchLoad(true);
    if (!searchData.data_inicio) {
      setSearchLoad(false)
      return Alert.error('É necessário selecionar a data de início');
    }
    if (!searchData.data_fim) {
      setSearchLoad(false)
      return Alert.error('É necessário selecionar a data final');
    }

    let dataLocalizacao = [...searchDataLocalizacao];

    var indice = dataLocalizacao.indexOf("Todos");

    if (!(indice < 0)){
      dataLocalizacao.splice(indice, 1);
    }
    const retorno = await dashboardApi.dashboardBrigadaPerildo(
      searchDataSelect,
      searchData.data_inicio,
      searchData.data_fim,
      dataLocalizacao
    );
    if (!retorno.isError){
      setDatesBetween(retorno.data.cabecalho);
      setData(retorno.data.dados);
    }
    setSearchLoad(false)
  }

  const excel = async () => {
    let dataLocalizacao = searchDataLocalizacao ? [...searchDataLocalizacao] : [];

    var indice = dataLocalizacao.indexOf("Todos");
    dataLocalizacao.splice(indice, 1)
    // @ts-ignore
    let url = `dashboard/excel/brigadaPeriodo?dt_inicio=${searchData['data_inicio']}&dt_fim=${searchData['data_fim']}&local=${dataLocalizacao && dataLocalizacao !== 'Todos' ? dataLocalizacao : ''}&id_loja=${searchDataSelect && searchDataSelect !== 'todos' ? searchDataSelect : ''}`;

    api.get(url, { responseType: 'blob' }).then(({ data }) => {
      const filename = 'BragadaDeVencimento.xlsx';
      const blob = new Blob([data], { type: 'application/vnd.ms-excel' });
      saveAs(blob, filename);
    })
      .catch(error => {
        console.log('aki')
      });
  }

  const totalGeral: number[] = [];
  return(
    <PageContainer menuTitle="Validade X Período" pageTitle="Brigada de vencimento por periodo">
      <PageCard
        header={
          <div className="flex items-center justify-between">
            <Title color="primary">Brigada de vencimento por periodo</Title>
          </div>
        }
      >
        <div className="grid grid-cols-12 gap-5 pb-7">
          <Select
            label="Seleciona loja"
            className="col-span-3 z-10"
            value={searchDataSelect}
            setValue={v => setSearchDataSelect(v)}
            items={selectStore}
          />

          <Select
            multiple={true}
            label="Seleciona localização"
            className="col-span-2 z-10"
            value={searchDataLocalizacao}
            setValue={v => setSearchDataLocalizacao(v)}
            items={selectLocalizacao}
            disabled={!searchDataSelect}
          />

          <Input
            label="Data inicial"
            type={"date"}
            className="col-span-2"
            value={searchData.data_inicio}
            setValue={(v) => setSearchData({...searchData, ['data_inicio']: v})}
          />
          <Input
            label="Data final"
            type={"date"}
            className="col-span-2"
            value={searchData.data_fim}
            setValue={(v) => setSearchData({...searchData, ['data_fim']: v})}
          />

          <Button
            className="min-w-[147px] h-[37.5px] mt-[1.5rem]"
            onClick={() => handleClick()}
            loading={searchLoad}
          >
            Buscar dados
          </Button>
          {
            data.length ?
              <Button
                className="min-w-[147px] h-[37.5px] mt-[1.5rem] ml-[5rem]"
                onClick={() => excel()}
                loading={searchLoad}
              >
                Gerar excel
              </Button>
              : ''
          }
        </div>
        <div className={'flex-row'}>
          <div className={'pb-5 overflow-auto tableContainer'}>
            {
              data.length ?
                <table className="table-auto w-[100%]">
                  <thead className="bg-light border-b sticky top-0">
                    <tr className={'border-t '}>
                      <th className={'text-start py-3 px-5 '}>EAN</th>
                      <th className={'text-start py-3 px-5'}>Produto</th>
                      <th className={'text-start py-3 px-5'}>Loja</th>
                      <th className={'text-start py-3 px-5'}>Validade</th>
                      <th className={'text-start py-3 px-5'}>Local</th>
                      {
                        datesBetween.map((date: any, key) => {
                          // @ts-ignore
                          return(<th className={'text-start py-3 px-5'}>{Str.convertDate(date).substring(0, 5)}</th>);
                        })
                      }
                      <th className={'text-start py-3 px-5'}>Totais Geral</th>
                    </tr>
                  </thead>
                  <tbody>
                  {
                    data && data.map((dt: any) => {

                      return dt.local.map((loc: any) => {
                          let sum = 0;
                        return(
                            <tr className={'border-t'}>
                              <td className={'text-start py-3 px-5 '}>{dt.ean}</td>
                              <td className={'text-start py-3 px-5'}>{dt.produto}</td>
                              <td  className={'text-start py-3 px-5'}>{loc.loja}</td>
                              <td  className={'text-start py-3 px-5'}>{Str.convertDate(dt.validade)}</td>
                              <td className={'text-start py-3 px-5'}>{loc.descricao}</td>
                              {
                                datesBetween.map((date, key) => {
                                  if (loc.leituras[date]){
                                    sum = loc.leituras[date];
                                  }
                                  return <td className={'text-start py-3 px-5'}>{loc.leituras[date]}</td>
                                })
                              }
                              <td className={'text-start py-3 px-5'}>{sum}</td>
                            </tr>
                          )
                        })

                    })
                  }
                  </tbody>
                </table>
                :
                ''
            }
          </div>
        </div>


      </PageCard>
    </PageContainer>
  )
}