import React, {useEffect, useState} from 'react';
import Alert from "../../../helpers/Alert";
import lojaApi from '../../../services/lojaApi';
import PageContainer from '../../../components/container/PageContainer';
import Title from '../../../components/text/Title';
import PageCard from '../../../components/card/PageCard';
import Select from '../../../components/select/Select';
import Input from '../../../components/input/Input';
import Button from '../../../components/button/Button';
import localizacaoApi from '../../../services/localizacaoApi';
import dashboardApi from '../../../services/dashboardApi';
import {CChart} from '@coreui/react-chartjs';
import Str from '../../../helpers/Str';

export default function LocalizationCount() {
  const [searchData, setSearchData] = useState<any>({
    'data_inicio': new Date().toISOString().split("T")[0],
    'data_fim': new Date().toISOString().split("T")[0],
  });
  const [selectStore, setSelectStore] = useState([]);
  const [searchDataSelect, setSearchDataSelect] = useState<any>('');

  const [selectLocalizacao, setSelectLocalizacao] = useState<any>([]);
  const [searchDataLocalizacao, setSearchDataLocalizacao] = useState<any>(['Todos']);

  const [searchLoad, setSearchLoad] = useState(false);

  const [data, setData] = useState<any>([]);

  async function loadData() {
    Alert.await(`Carregando dados...`);
    const {data, isError} = await lojaApi.listAll();
    if (isError) return alert(data.message || 'Falha ao carregar dados da loja.');
    let dtSelect = data.map((res: any) => ({label:res.razao_social, key: res.id}));
    dtSelect.unshift({label:'Todos', key: ''});
    setSelectStore(dtSelect as any);

    const dataLocalization = await localizacaoApi.listAll();
    if (dataLocalization.isError) return alert(dataLocalization.data.message || 'Falha ao carregar dadas localizações.');
    let dtlocSelect = dataLocalization.data.map((res: any) => ({label:res.descricao, key: res.descricao}));
    dtlocSelect.unshift({label:'Todos', key: 'Todos'});
    setSelectLocalizacao(dtlocSelect as any);
    Alert.close();
  }

  useEffect(() => {
    loadData();
  }, []);

  const handleClick = async () => {
    setSearchLoad(true);
    if (!searchData.data_inicio) {
      setSearchLoad(false);
      return Alert.error('É necessário selecionar a data de início');
    }
    if (!searchData.data_fim) {
      setSearchLoad(false);
      return Alert.error('É necessário selecionar a data final');
    }
    if (!searchDataLocalizacao) {
      setSearchLoad(false);
      return Alert.error('É necessário selecionar a localização');
    }

    let dataLocalizacao = [...searchDataLocalizacao];

    var indice = dataLocalizacao.indexOf("Todos");
    dataLocalizacao.splice(indice, 1)

    const {data, isError} = await dashboardApi.dashboardLocalCount(
      searchDataSelect,
      searchData.data_inicio,
      searchData.data_fim,
      dataLocalizacao
    );
    setData(data);
    setSearchLoad(false);

  }

  return(
    <PageContainer menuTitle="Contagem de local" pageTitle="Contagem de local">
      <PageCard
        header={
          <div className="flex items-center justify-between">
            <Title color="primary">Contagem de local</Title>
          </div>
        }
      >
        <div className="grid grid-cols-12 gap-5 pb-7">
          <Select
            label="Seleciona loja"
            className="col-span-3"
            value={searchDataSelect}
            setValue={v => setSearchDataSelect(v)}
            items={selectStore}
          />
          <Select
            multiple={true}
            label="Seleciona localização"
            className="col-span-3"
            value={searchDataLocalizacao}
            setValue={v => setSearchDataLocalizacao(v)}
            items={selectLocalizacao}
            disabled={!searchDataSelect}
          />

          <Input
            label="Data inicial"
            type={"date"}
            className="col-span-2"
            value={searchData.data_inicio}
            setValue={(v) => setSearchData({...searchData, ['data_inicio']: v})}
          />
          <Input
            label="Data final"
            type={"date"}
            className="col-span-2"
            value={searchData.data_fim}
            setValue={(v) => setSearchData({...searchData, ['data_fim']: v})}
          />

          <Button
            className="min-w-[147px] h-[37.5px] mt-[1.5rem]"
            onClick={() => handleClick()}
            loading={searchLoad}
          >
            Buscar dados
          </Button>
        </div>
        <div className={'flex-row'}>
          {
            data && data.map((dt: any) => {
              let cnt = 0;
              const labeldata = dt.leituras.map((lt: any) => Str.convertDate(lt.data));
              const labelqtd = dt.leituras.map((lt: any) => lt.quantidade);
              return(
                <div className={'w-[100%] flex'}>
                  <div className={'pb-5 w-[50%]'}>
                    <div className={'py-4'}>
                      <h1 className={'text-lg font-bold'}>
                        {dt.localizacao}
                      </h1>
                    </div>

                    <table className="table-auto w-[100%]">
                      <thead className="bg-light border-b">
                      <tr className={'border-t'}>
                        <th className={'text-start py-3 px-5'}>Data</th>
                        <th className={'text-start py-3 px-5'}>Quantidades</th>
                      </tr>
                      </thead>
                      <tbody>
                      {
                        dt.leituras.map((leitura: any, key) => {
                          if (dt.leituras.length === key+1){
                            cnt += leitura.quantidade;
                            return(
                              <>
                                <tr>
                                  <td className="px-5">{Str.convertDate(leitura.data)}</td>
                                  <td className="px-5">{leitura.quantidade}</td>
                                </tr>
                                <tr className={'border-t'}>
                                  <td className="px-5 font-bold">Total:</td>
                                  <td className="px-5">{cnt}</td>
                                </tr>
                              </>
                            )
                          }else{
                            cnt += leitura.quantidade;
                            return(
                              <tr>
                                <td className="px-5">{Str.convertDate(leitura.data)}</td>
                                <td className="px-5">{leitura.quantidade}</td>
                              </tr>
                            )
                          }
                        })
                      }
                      </tbody>
                    </table>
                  </div>
                  <div className={'pb-5 w-[50%]'}>
                    <CChart
                      type="bar"
                      data={{
                        labels: labeldata,
                        datasets: [
                          {
                            backgroundColor: [
                              '#2125a5',
                              '#e4f914',
                              '#032738',
                              '#ac3128',
                              '#bad3dd',
                              '#63c12c',
                              '#50b8c8',
                              '#e01aab',
                              '#ab1ae0',
                              '#44807e',
                            ],
                            data: labelqtd,
                          },
                        ],
                      }}
                      options={{
                        plugins: {
                          legend: {
                            display: false,
                            /*labels: {
                              color: '#000',
                            }*/
                          }
                        },
                        scales: {
                          x: {
                            grid: {
                              color: "#DDDDE0",
                            },
                            ticks: {
                              color: '#000',
                            },
                          },
                          y: {
                            grid: {
                              color: "#DDDDE0",
                            },
                            ticks: {
                              color: '#000',
                            },
                          },
                        },
                      }}
                    />
                  </div>
                </div>

              )
            })
          }
        </div>
      </PageCard>
    </PageContainer>
  )
}