import { QueryParams, Secao } from '../types';
import {getAll, create, update, getOne, deleteOne, listAll} from './api';

const endpoint = 'secao';
const allConsult = 'secao?order=nome,asc';
export default {
  all: async (params?: QueryParams) => getAll<Secao>({ endpoint, params }),
  listAll: async () => listAll<Secao>({ endpoint, allConsult }),
  /*one: async (id: string) => getOne<Secao>({ endpoint, id }),
  destroy: async (id: string) => deleteOne({ endpoint, id }),
  create: async (formData: Partial<Secao>) => create<Secao>({ endpoint, formData }),
  update: async (id: string, formData: Partial<Secao>) => update<Secao>({ endpoint, formData, id }),*/
};
