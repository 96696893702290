import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';

import LogoSrc from '../../../assets/images/logo.png';
import EstoqueSrc from '../../../assets/images/estoque.jpg';

import Title from '../../../components/text/Title';
import ColoredButton from '../../../components/button/ColoredButton';
import Input from '../../../components/input/Input';
import { ForgotPassData } from '../../../types';
import Form from '../../../components/form/Form';
import authApi from '../../../services/authApi';
import useForm from '../../../hooks/useForm';
import Storage from '../../../helpers/Storage';
import Alert from '../../../helpers/Alert';

export default function ForgotPassord() {
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { form, isFormInvalid, handleChange } = useForm<ForgotPassData>();

  async function handleSubmit() {
    setErrorMessage('');
    setIsLoading(true);
    const { data, isError } = await authApi.forgotPass(form);

    if (isError) {
      setIsLoading(false);
      setErrorMessage(data.message);
      return;
    }
    setIsLoading(false);
    Alert.success('Um e-mail foi enviado para você');
    //window.location.href = '/';
  }

  return (
    <div className="flex items-center justify-center h-screen p-5">
      <div className="lg:shadow lg:grid lg:grid-cols-2 w-full lg:w-max">
        <Form
          className="bg-background shadow lg:shadow-none p-9 grid place-items-center mx-auto w-full max-w-[410px] rounded-tl-md rounded-bl-md"
          onSubmit={handleSubmit}
        >
          <img src={LogoSrc} className="max-w-[104px]" />

          <Title size="2xl" color="primary" className="mb-5 mt-8">Recuperação de senha</Title>
          <p className="text-md text-secondary">Informe seu email.</p>

          {!!errorMessage?.length && <p className="mt-5 text-red-600">{errorMessage}</p>}

          <Input
            large
            hideLabel
            className="my-5"
            value={form?.email}
            setValue={(v) => handleChange(v, 'email')}
          />

          <ColoredButton
            className="mt-6 mb-4"
            disabled={isFormInvalid}
            loading={isLoading}
            type="submit"
          >
            Entrar
          </ColoredButton>

          <a className="text-primary hover:text-primary-700 transition cursor-pointer" href={'/entrar'}>
            Voltar
          </a>
        </Form>

        <aside className="hidden lg:block">
          <div className="w-[410px] h-full relative">
            <img src={EstoqueSrc} className="object-cover w-full h-full rounded-tr-md rounded-br-md" />
            <div className="absolute inset-0 linear-gradient rounded-tr-md rounded-br-md opacity-80"></div>
            <div className="absolute inset-0 p-14 flex items-center">
              <h2 className="text-in-primary text-lg max-w-[300px]">
                Otimize seus processos de armazenagem e gerenciamento de estoques com a ajuda de nossa plataforma Inovatta!
              </h2>
            </div>
          </div>
        </aside>
      </div>
    </div>
  );
}
