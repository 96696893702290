/*import { QueryParams, Departamento } from '../types';*/
import {deleteOne} from './api';

const endpoint = 'leitura-produtos_fotos';

export default {
  /*all: async (params?: QueryParams) => getAll<Departamento>({ endpoint, params }),
  listAll: async () => listAll<Departamento>({ endpoint }),
  one: async (id: string) => getOne<Departamento>({ endpoint, id }),*/
  destroy: async (id: string) => deleteOne({ endpoint, id }),
  /*create: async (formData: Partial<Departamento>) => create<Departamento>({ endpoint, formData }),
  update: async (id: string, formData: Partial<Departamento>) => update<Departamento>({ endpoint, formData, id }),*/
};
