import React, { Fragment, useState } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { BiCheck, BiCaretDown } from 'react-icons/bi';
import Str from '../../../helpers/Str';

export interface SelectItem<T> {
  key: T;
  label: string | number;
}

export interface SelectProps<T> {
  items: SelectItem<T>[];
  className?: string;
  label?: string;
  error?: string;
  value?: T;
  setValue?: (value: T) => void;
  name?: string;
  multiple?: boolean;
  disabled?: boolean;
}

export default function Select<T = any>({
  items,
  label,
  error,
  className,
  setValue,
  value,
  name,
  multiple,
  disabled
}: SelectProps<T>) {
  const [selected, setSelected] = useState<T>(value as T);
  const [dataSelectedLabel, setDataSelectedLabel] = useState<any>([]);

  function handleChange(value: any) {
    setSelected(value);
    if (setValue) {
      /*console.log(value);
      const teste = items.find(i => i.key === value[value.length - 1]);
      console.log(teste?.label);*/
      setValue(value);
      setDataSelectedLabel(value)
    }
  }

  return (
    <label className={className}>
      <span>{label}</span>
      <Listbox name={name} value={selected} onChange={handleChange} multiple={multiple} disabled={disabled}>
        <div className="relative">
          <Listbox.Button className={Str.tw(
            'bg-light border border-border rounded-md outline-none h-[37.5px] pl-3 pr-1 w-full',
            'focus:bg-background focus:border-primary transition',
            'flex items-center justify-between min-w-[75px]'
          )}>
            {
              multiple ?
                <span className="block truncate pr-1">{dataSelectedLabel && dataSelectedLabel.map(i => i).join(', ') || ''}</span>
                :
                <span className="block truncate pr-1">{items.find(i => i.key === selected)?.label || ''}</span>
            }
            <BiCaretDown
              className="h-4 w-4 text-gray-400"
              aria-hidden="true"
            />
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {items.map((item, itemIdx) => (
                <Listbox.Option
                  key={`listbox-option--${itemIdx}`}
                  className={({ active }) =>
                    `relative cursor-default select-none py-2 pl-10 pr-4 ${
                      active ? 'bg-primary text-in-primary' : 'text-gray-900'
                    }`
                  }
                  value={item.key}
                >
                  {({ selected }) => (
                    <>
                      <span
                        className={`block truncate ${
                          selected ? 'font-medium' : 'font-normal'
                        }`}
                      >
                        {item.label}
                      </span>
                      {selected ? (
                        <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-primary">
                          <BiCheck className="h-5 w-5" aria-hidden="true" />
                        </span>
                      ) : null}
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
      <span className="text-red-600 ">{error}</span>
    </label>
   
  );
}
