import {QueryParams, Loja, Localizacao} from '../types';
import api, {
  getAll,
  listAll,
  create,
  update,
  getOne,
  deleteOne,
  RequestError,
  RequestResponse,
  GetAllParams,
  PaginatedRequestResponse
} from './api';
import Storage from '../helpers/Storage';

const endpoint = 'lojas';
const getEndpoint = 'lojas/site';
export default {
  all: async (params?: QueryParams) => getAll<Loja>({ endpoint: getEndpoint, params }),
  one: async (id: string) => getOne<Loja>({ endpoint, id }),
  /*listAll: async (params?: QueryParams) => listAll<Loja>({ endpoint }),*/
  destroy: async (id: string) => deleteOne({ endpoint, id }),
  create: async (formData: Partial<Loja>) => create<Loja>({ endpoint, formData }),
  update: async (id: string, formData: Partial<Loja>) => update<Loja>({ endpoint, formData, id }),
  async site (params?: QueryParams): Promise<RequestError | RequestResponse> {
    const data = Storage.getUserData();

    const page    = params?.page    ? 'page='    + params?.page    : 'page=1';
    const perPage = params?.perPage ? '&perpage=' + params?.perPage : '';
    const search  = params?.search  ? '&search='  + params?.search  : '';
    const clienteId = params?.withClienteId ? '&cliente_id=' + data?.cliente?.id : '';

    try {
      const { data } = await api.get(`${endpoint+'/site'}?${page}${perPage}${search}${clienteId}`);
      return { data, isError: false };
    } catch (err: any) {
      return {
        data: err?.response?.data ?? { message: 'Falha ao processar requisição' },
        isError: true
      };
    }
  },
  async listAll (params?: QueryParams): Promise<RequestError | RequestResponse> {
    const data = Storage.getUserData();
    try {
      const { data } = await api.get(`${endpoint+'/site'}`);
      return { data, isError: false };
    } catch (err: any) {
      return {
        data: err?.response?.data ?? { message: 'Falha ao processar requisição' },
        isError: true
      };
    }
  }
};
