import Select from "react-select";
interface IReactSelect {
  label?: string;
  className?: string;
  required?: boolean;
  options?: any;
  value?: any;
  setValue?: any;
  isMult?: boolean;
}
export default function ReactSelect(
  {
    label,
    className,
    required,
    options,
    value,
    setValue,
    isMult,
  }
){

  return(
    <div className={className}>
      {
        label ?
          <label>
            {label}
            {required ? <span className='text-red-600'> *</span> : ''}
          </label>
          :
          ''
      }
      <Select
        isMulti={isMult}
        isClearable={true}
        options={options}
        placeholder={label}
        value={value}
        onChange={e => setValue(e)}
      />
    </div>
  )
}