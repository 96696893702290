import { Cliente, Usuario } from '../types';
import React, { createContext, Dispatch, useState, SetStateAction } from 'react';

export interface AppContextType {
  usuario?: Usuario;
  setUsuario: React.Dispatch<React.SetStateAction<Usuario>>;
  cliente?: Cliente;
  setCliente: React.Dispatch<React.SetStateAction<Cliente>>;
  menus?: any;
  setMenus?: any;
}

export const AppContext = createContext({} as AppContextType);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function AppProvider({ children }: any) {
  const [usuario, setUsuario] = useState<Usuario>({} as Usuario);
  const [cliente, setCliente] = useState<Cliente>({} as Cliente);
  const [menus, setMenus] = useState<any>({} as any);
  return (
    <AppContext.Provider value={{
      usuario,
      setUsuario,
      cliente,
      setCliente,
      menus,
      setMenus,
    }}>{children}</AppContext.Provider>
  );
}
