import { QueryParams, Departamento } from '../types';
import {getAll, create, update, getOne, deleteOne, listAll} from './api';

const endpoint = 'departamento';
const allConsult = 'departamento?order=nome,asc';
export default {
    all: async (params?: QueryParams) => getAll<Departamento>({ endpoint, params }),
    listAll: async () => listAll<Departamento>({ endpoint, allConsult }),
    /*one: async (id: string) => getOne<Departamento>({ endpoint, id }),
    destroy: async (id: string) => deleteOne({ endpoint, id }),
    create: async (formData: Partial<Departamento>) => create<Departamento>({ endpoint, formData }),
    update: async (id: string, formData: Partial<Departamento>) => update<Departamento>({ endpoint, formData, id }),*/
};
