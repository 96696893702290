/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useEffect} from 'react';
import { BiEdit } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';
import { PaginatedRequestResponse, RequestError } from '../../../services/api';
import { QueryParams } from '../../../types';

import useTable from '../../../hooks/useTable';

import PageContainer from '../../../components/container/PageContainer';
import SearchPageCard from '../../../components/card/SearchPageCard';
import Table, { TableColumn1, TableColumn2 } from '../../../components/table/Table';
import IconButton from '../../../components/button/IconButton';


export interface ListPageLayoutProps<T> {
  menuTitle?: string;
  pageTitle?: string;
  searchPlaceholder: string;
  loader: (params?: QueryParams | undefined) => Promise<RequestError<any> | PaginatedRequestResponse<T>>;
  columns: (TableColumn1<T> | TableColumn2<T>)[];
  creationEndpoint: string;
  creationLabel: string;
  edit?: boolean;
}

export default function ListPageLayout<T>({
  menuTitle,
  searchPlaceholder,
  loader,
  columns,
  creationEndpoint,
  creationLabel,
  edit
}: ListPageLayoutProps<T>) {
  const navigate = useNavigate();

  const { tableProps, onSearch } = useTable<T>({
    dataLoader: async (params) => {
      const { data, isError } = await loader(params);
      return isError ? false : data;
    }
  });

  useEffect(() => {
    if (edit === undefined){
      edit = true;
    }

    if (edit) {
      if(columns[0]?.title !== 'Editar'){
        columns.unshift({
          title: 'Editar',
          render: (item) => <IconButton icon={BiEdit} onClick={() => navigate(`${creationEndpoint}/${(item as any).id}`)} />
        });
      }

    }
  }, [edit])



  return (
    <PageContainer menuTitle={menuTitle}>
      <SearchPageCard 
        placeholder={searchPlaceholder}
        creationPath={creationEndpoint}
        creationLabel={creationLabel}  
        onSearch={onSearch}
      />

      <Table<T> {...tableProps} columns={[
        ...columns,
      ]} />
    </PageContainer>
  );
}
