import { Cliente, Usuario } from '../types';

/* eslint-disable @typescript-eslint/no-explicit-any */
export default {
  KEY__USER_DATA: '@inovatta:user_data',
  KEY__API_TOKEN: '@inovatta:api_token',
  USER_MENU_DATA: '@inovatta:user_menu',


  set(key: string, value: any) {
    if (typeof value === 'object') {
      value = JSON.stringify(value);  
    }
    
    this.setRaw(key, value);
  },

  setRaw(key: string, value: any) {
    localStorage.setItem(key, value);
  },

  get(key: string): string | object | null {
    let value = this.getRaw(key);

    if (value) {
      try {
        value = JSON.parse(value);
      // eslint-disable-next-line no-empty
      } catch (err) {}
    }

    return value;
  },

  getRaw(key: string) {
    return localStorage.getItem(key);
  },

  remove(key: string) {
    localStorage.removeItem(key);
  },

  setApiToken(token: string) {
    this.setRaw(this.KEY__API_TOKEN, token);
  },

  getApiToken() {
    return this.getRaw(this.KEY__API_TOKEN);
  },

  removeApiToken() {
    this.remove(this.KEY__API_TOKEN);
  },

  setUserData(data: { usuario: Usuario, cliente: Cliente }) {
    this.set(this.KEY__USER_DATA, data);
  },

  getUserData() {
    return this.get(this.KEY__USER_DATA) as { usuario: Usuario, cliente: Cliente } | null;
  },

  removeUserData() {
    this.remove(this.KEY__USER_DATA);
  },

  setUserMenu(data: any) {
    this.set(this.USER_MENU_DATA, data) as any | null;
  },

  getUserMenu() {
    return this.get(this.USER_MENU_DATA);
  },
};
