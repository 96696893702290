/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';

import { PaginatedData, QueryParams } from '@/types';
import Storage from '../helpers/Storage';

export interface PaginatedRequestResponse<T = any> {
  data: PaginatedData<T>
  isError: false;
}

export interface RequestResponse<T = any> {
  data: T;
  isError: false;
}

export interface RequestError<T = any> {
  data: T | { message: string; };
  isError: true;
}

export interface GetAllParams {
  endpoint: string;
  params?: QueryParams;
}

export interface GetOneParams {
  endpoint: string;
  id: number | string;
  relationship?: string;
}

export interface DeleteParams {
  endpoint: string;
  id: number | string;
}
export interface CreateParams<T> {
  endpoint: string;
  formData: Partial<T>;
}

export interface UpdateParams<T> {
  endpoint: string;
  id: number | string;
  formData: Partial<T>;
}

const api = axios.create({
  baseURL: process.env.REACT_APP_API,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  }
});

api.defaults.headers.common['Authorization'] = 'Bearer ' + Storage.getApiToken();
export default api;

export async function getAll<T>({
  params,
  endpoint
}: GetAllParams): Promise<RequestError | PaginatedRequestResponse<T>> {
  const data = Storage.getUserData();

  const page    = params?.page    ? 'page='    + params?.page    : 'page=1';
  const perPage = params?.perPage ? '&perpage=' + params?.perPage : '';
  const search  = params?.search  ? '&search='  + params?.search  : '';
  const clienteId = params?.withClienteId ? '&cliente_id=' + data?.cliente?.id : '';
  const lojaId = params?.loja_id ? '&loja_id=' + params?.loja_id : '';

  try {
    const { data } = await api.get(`${endpoint}?${page}${perPage}${search}${clienteId}${lojaId}`);
    return { data, isError: false };
  } catch (err: any) {
    let dataErro = err?.response?.data;
    if (err?.response?.data){
      if (err?.response?.data.error){
        dataErro = err?.response?.data.error;
      }
    }
    return {
      data: dataErro ?? { message: 'Falha ao processar requisição' },
      isError: true
    };
  }
}

export async function listAll<T>({
  endpoint,
  allConsult
}: {endpoint: string, allConsult?: any}): Promise<RequestError | RequestResponse<T[]>> {
  try {
    let finalEndpoint = endpoint
    if (allConsult){
      finalEndpoint = allConsult
    }
    const { data } = await api.get(`${finalEndpoint}`);
    return { data, isError: false };
  } catch (err: any) {
    let dataErro = err?.response?.data;
    if (err?.response?.data){
      if (err?.response?.data.error){
        dataErro = err?.response?.data.error === 'permission_denied' ? 'Permissão negada' : err?.response?.data.error;
      }
    }
    return {
      data: dataErro ?? { message: 'Falha ao processar requisição' },
      isError: true
    };
  }
}

export async function getOne<T>({
  endpoint,
  id,
  relationship
}: GetOneParams): Promise<RequestError | RequestResponse<T>> {
  try {
    let url: any = `${endpoint}/${id}`;
    if (relationship){
      url += `?relations=${relationship}`;
    }

    const { data } = await api.get(url);
    return { data: data as T, isError: false };
  } catch (err: any) {
    let dataErro = err?.response?.data;
    if (err?.response?.data){
      if (err?.response?.data.error){
        dataErro = err?.response?.data.error;
      }
    }
    return {
      data: dataErro ?? { message: 'Falha ao processar requisição' },
      isError: true
    };
  }
}

export async function create<T>({
  endpoint,
  formData
}: CreateParams<T>): Promise<RequestError | RequestResponse<T>> {
  try {
    const { data } = await api.post(endpoint, formData);
    return { data, isError: false };
  } catch (err: any) {
    let dataErro = err?.response?.data;
    if (err?.response?.data){
      if (err?.response?.data.error){
        //dataErro = err?.response?.data.error;
        dataErro = err?.response?.data.error === 'permission_denied' ? 'Permissão negada' : err?.response?.data.error;
      }
    }
    return { 
      data: dataErro ?? { message: 'Falha ao processar requisição' },
      isError: true
    };
  }
}

export async function deleteOne<T = any>({
  endpoint,
  id
}: DeleteParams): Promise<RequestError | RequestResponse<T>> {
  try {
    const { data } = await api.delete(`${endpoint}/${id}`);
    return { data: data as T, isError: false };
  } catch (err: any) {
    let dataErro = err?.response?.data;
    if (err?.response?.data){
      if (err?.response?.data.error){
        dataErro = err?.response?.data.error;
      }
    }
    return {
      data: dataErro ?? { message: 'Falha ao processar requisição' },
      isError: true
    };
  }
}

export async function update<T>({
  endpoint,
  id,
  formData
}: UpdateParams<T>): Promise<RequestError | RequestResponse<T>> {
  try {
    const { data } = await api.put(`${endpoint}/${id}`, formData);
    return { data, isError: false };
  } catch (err: any) {
    let dataErro = err?.response?.data;
    if (err?.response?.data){
      if (err?.response?.data.error){
        dataErro = err?.response?.data.error;
      }
    }
    return {
      data: dataErro ?? { message: 'Falha ao processar requisição' },
      isError: true
    };
  }
}

export function makeCrudObject<T>(endpoint) {
  return {
    all: async (params?: QueryParams) => getAll<T>({ endpoint, params }),
    one: async (id: string) => getOne<T>({ endpoint, id }),
    destroy: async (id: string | number) => deleteOne({ endpoint, id }),
    create: async (formData: Partial<T>) => create<T>({ endpoint, formData }),
    update: async (id: string | number, formData: Partial<T>) => update<T>({ endpoint, formData, id }),
  };
}