import React, {useEffect, useState} from 'react';

import PageContainer from '../../../components/container/PageContainer';
import PageCard from '../../../components/card/PageCard';
import Title from "../../../components/text/Title";
import Select from "../../../components/select/Select";
import Button from "../../../components/button/Button";
import Alert from "../../../helpers/Alert";
import lojaApi from '../../../services/lojaApi';
import Input from '../../../components/input/Input';
import produtoApi from '../../../services/produtoApi';
import dashboardApi from '../../../services/dashboardApi';
import {CChart} from '@coreui/react-chartjs';
import Str from '../../../helpers/Str';

import './quantityCount.css';
import departamentoApi from '../../../services/departamentoApi';
export default function QuantityCount() {
  const [selectStore, setSelectStore] = useState([]);
  const [searchDataSelect, setSearchDataSelect] = useState<any>('');

  const [selectDepartamentos, setSelectDepartamentos] = useState<any>([]);
  const [searchDataDepartamento, setSearchDataDepartamento] = useState<any>(['Todos']);

  const [data, setData] = useState<any>();
  const [datesBetween, setDatesBetween] = useState<any>([]);
  const [graphData, setGraphData] = useState<any>();

  const [searchData, setSearchData] = useState<any>({
    'data_inicio': new Date().toISOString().split("T")[0],
    'data_fim': new Date().toISOString().split("T")[0],
  });

  const [searchLoad, setSearchLoad] = useState(false);
  async function loadData() {
    Alert.await(`Carregando dados...`);
    const {data, isError} = await lojaApi.listAll();
    if (isError) return alert(data.message || 'Falha ao carregar dados da loja.');
    let dtSelect = data.map((res: any) => ({label:res.razao_social, key: res.id}));
    dtSelect.unshift({label:'Todos', key: ''});
    setSelectStore(dtSelect as any);

    const dataProd = await departamentoApi.listAll();
    if (dataProd.isError) return alert(dataProd.data.message || 'Falha ao carregar dados das seções.');
    let dtDepartamentoSelect = dataProd.data.map((res: any) => ({label:res.nome, key: res.nome}));
    dtDepartamentoSelect.unshift({label:'Todos', key: 'Todos'});
    setSelectDepartamentos(dtDepartamentoSelect as any);

    Alert.close();
  }

  useEffect(() => {
    loadData();
  }, []);

  const handleClick = async () => {
    setSearchLoad(true);
    if (!searchData.data_inicio) {
      setSearchLoad(false)
      return Alert.error('É necessário selecionar a data de início');
    }
    if (!searchData.data_fim) {
      setSearchLoad(false)
      return Alert.error('É necessário selecionar a data final');
    }

    if (!searchDataDepartamento.length) {
      setSearchLoad(false)
      return Alert.error('É necessário selecionar o departamento');
    }
    let dataDepartamento = [...searchDataDepartamento];

    var indice = dataDepartamento.indexOf("Todos");

    if (!(indice < 0)){
      dataDepartamento.splice(indice, 1);
    }

    //console.log(searchData.data_inicio, new Date(searchData.data_inicio));
    let date1 = new Date(searchData.data_inicio);
    let date2= new Date(searchData.data_fim);
    date1.setDate(date1.getDate()+1);
    date2.setDate(date2.getDate()+1);
    //const datesBetween = Str.getDatesBetween(date1, date2);

    const returno = await dashboardApi.dashboardQuantityCount(
      searchDataSelect,
      searchData.data_inicio,
      searchData.data_fim,
      dataDepartamento
      );
    setDatesBetween(Str.getDatesBetween(date1, date2));
    setData(returno.data);

    if (returno.data.length) {
      const diffData = [...returno.data].map( item => item.leituras.map(leitura => leitura.data))
        .reduce((a,b) => [...a, ...b])
        .filter((value, index, array) => {
          return array.indexOf(value) === index;
        })
        .sort();

      /* let graphData = [];*/
      let dataGraph: { data: any; quantidade: any; departamento: any; }[] = [];

      returno.data.forEach(item => {
        diffData.forEach(date => {
          const leitura = item.leituras.find(leitura => leitura.data === date);
          dataGraph.push({
            "data": Str.convertDate(date),
            "quantidade": leitura ? leitura.quantidade : 0,
            "departamento": item.departamento,
          });
        })
      });
      dataGraph.forEach(gd => {
        const gdIndex = dataGraph.findIndex(gdfi => gdfi.data === gd.data && gdfi.departamento === `Total`);
        if(gdIndex >= 0){
          return dataGraph[gdIndex].quantidade += gd.quantidade;
        }
        dataGraph.push({
          "data": gd.data,
          "quantidade": gd.quantidade,
          "departamento": `Total`,
        });
      });
      const labels = Object.keys(Str.arrayGroupBy(dataGraph, 'departamento'));
      dataGraph = Str.arrayGroupBy(dataGraph, 'data');

      const backGoundColor = [
        '#2125a5',
        '#e4f914',
        '#032738',
        '#ac3128',
        '#bad3dd',
        '#63c12c',
        '#50b8c8',
        '#e01aab',
      ]
      const finalDt = {
        labels,
        datasets: Object.keys(dataGraph).map((key, index) =>{
          const gd = dataGraph[key];
          return(
            {
              label: key,
              backgroundColor: backGoundColor[index],
              data: gd.map(g => g.quantidade),
              /*parsing: {
                xAxisKey: 'data',
                yAxisKey: 'quantidade',
              }*/
            }
          )
        }),
      }
      setGraphData(finalDt)
    }
    setSearchLoad(false);
  };


  const totalGeral: number[] = [];

  return (
    <PageContainer menuTitle="Contagem de quantidade" pageTitle="Contagem de quantidade">
      <PageCard
        header={
          <div className="flex items-center justify-between">
            <Title color="primary">Contagem de quantidade</Title>
          </div>
        }
      >
        <div className="grid grid-cols-12 gap-5 pb-7">
          <Select
            label="Seleciona loja"
            className="col-span-3"
            value={searchDataSelect}
            setValue={v => setSearchDataSelect(v)}
            items={selectStore}
          />
          <Select
            multiple={true}
            label="Seleciona departamento"
            className="col-span-3"
            value={searchDataDepartamento}
            setValue={v => setSearchDataDepartamento(v)}
            items={selectDepartamentos}
          />

          <Input
            label="Data inicial"
            type={"date"}
            className="col-span-2"
            value={searchData.data_inicio}
            setValue={(v) => setSearchData({...searchData, ['data_inicio']: v})}
          />
          <Input
            label="Data final"
            type={"date"}
            className="col-span-2"
            value={searchData.data_fim}
            setValue={(v) => setSearchData({...searchData, ['data_fim']: v})}
          />

          <Button
            className="min-w-[147px] h-[37.5px] mt-[1.5rem]"
            onClick={() => handleClick()}
            loading={searchLoad}
          >
            Buscar dados
          </Button>
        </div>
        <div className={'flex-row'}>
          <div className={'pb-5 overflow-auto tableContainer'}>
            {
              data &&
              (
                <table className="table-auto w-[100%]">
                  <thead className="bg-light border-b">
                  <tr className={'border-t'}>
                    <th className={'text-start py-3 px-5'}>Departamento</th>
                    {
                      datesBetween.map((date: any, key) => {
                        return(<th className={'text-start py-3 px-5'}>{date.substring(0, date.length - 5)}</th>);
                      })
                    }
                    <th className={'text-start py-3 px-5'}>Totais</th>

                  </tr>
                  </thead>
                  <tbody>
                      {
                        data && data.map((dt: any) => {
                          let sum = 0;
                          dt.leituras.forEach(leitura => {
                            sum +=  parseInt(leitura.quantidade);
                          })
                          return(
                            <tr className={'border-t'}>
                              <td className={'text-start py-3 px-5'}>{dt.departamento}</td>
                              {
                                datesBetween.map((date, key) => {
                                  const leitura = dt.leituras.find(leitura => date === Str.convertDate(leitura.data));
                                  totalGeral[key] = totalGeral[key] || 0;
                                  if (leitura) {
                                    totalGeral[key] += parseInt(leitura.quantidade);
                                    return(<td className={'text-start py-3 px-5'}>{parseInt(leitura.quantidade)}</td>);
                                  }else{
                                    return(<td className={'text-start py-3 px-5'}>0</td>);
                                  }
                                })
                              }
                              <td className={'text-start py-3 px-5'}>{sum}</td>
                            </tr>
                          );
                        })
                      }
                      <tr className={'border-t'}>
                        <td className={'text-start py-3 px-5 font-bold'}>Total Geral</td>
                        {
                          datesBetween.map((date, key) => {
                            return(<td className={'text-start py-3 px-5 font-bold'}>{totalGeral[key]}</td>);
                          })
                        }
                        <td className={'text-start py-3 px-5 font-bold'}>{totalGeral.reduce((a, b) => a+b, 0)}</td>
                      </tr>
                  </tbody>
                </table>
              )
            }
          </div>
          <div className={'pb-5 w-[100%]'}>
            {
              graphData && <CChart
                type="bar"
                data={graphData}
                options={{
                  plugins: {
                    legend: {
                      labels: {
                        color: '#000',
                      }
                    }
                  },
                  scales: {
                    x: {
                      grid: {
                        color: "#DDDDE0",
                      },
                      ticks: {
                        color: '#000',
                      },
                    },
                    y: {
                      grid: {
                        color: "#DDDDE0",
                      },
                      ticks: {
                        color: '#000',
                      },
                    },
                  },
                }}
              />
            }

          </div>
            {
              /*data && data.map((dt: any) => {
                let cnt = 0;
                const labeldata = dt.leituras.map((lt: any) => Str.convertDate(lt.data));
                const labelqtd = dt.leituras.map((lt: any) => lt.quantidade);
                return(
                  <div className={'w-[100%]'}>
                    <div className={'pb-5 w-[100%]'}>
                      <div className={'py-4'}>
                        <h1 className={'text-lg font-bold'}>
                          {dt.departamento}
                        </h1>
                      </div>

                      <table className="table-auto w-[100%]">
                        <thead className="bg-light border-b">
                          <tr className={'border-t'}>
                            <th className={'text-start py-3 px-5'}>Departamento</th>

                            {
                              dt.leituras.map((leitura: any, key) => {
                                return(<th className={'text-start py-3 px-5'}>{Str.convertDate(leitura.data)}</th>);
                              })
                            }

                          </tr>
                        </thead>
                        <tbody>
                          <tr className={'border-t'}>
                            <td className={'text-start py-3 px-5'}>{dt.departamento}</td>
                            <td className={'text-start py-3 px-5'}>10</td>
                            <td className={'text-start py-3 px-5'}>30</td>
                            <td className={'text-start py-3 px-5'}>40</td>
                          </tr>
                        </tbody>
                        {/!*{
                          dt.leituras.map((leitura: any, key) => {
                            return(
                              <tr className={'border-t'}>
                                <th className={'text-start py-3 px-5'}>data</th>
                                <th className={'text-start py-3 px-5'}>quantidades</th>
                              </tr>
                            );
                          })
                        }*!/}
                        {/!*<thead className="bg-light border-b">
                        <tr className={'border-t'}>
                          <th className={'text-start py-3 px-5'}>data</th>
                          <th className={'text-start py-3 px-5'}>quantidades</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                          dt.leituras.map((leitura: any, key) => {
                            if (dt.leituras.length === key+1){
                              cnt += leitura.quantidade;
                              return(
                                <>
                                  <tr>
                                    <td className="px-5">{Str.convertDate(leitura.data)}</td>
                                    <td className="px-5">{leitura.quantidade}</td>
                                  </tr>
                                  <tr className={'border-t'}>
                                    <td className="px-5 font-bold">Total:</td>
                                    <td className="px-5">{cnt}</td>
                                  </tr>
                                </>
                              )
                            }else{
                              cnt += leitura.quantidade;
                              return(
                                <tr>
                                  <td className="px-5">{Str.convertDate(leitura.data)}</td>
                                  <td className="px-5">{leitura.quantidade}</td>
                                </tr>
                              )
                            }
                          })
                        }
                        </tbody>*!/}
                      </table>
                    </div>
                    <div className={'pb-5 w-[100%]'}>
                      <CChart
                        type="bar"
                        data={{
                          labels: labeldata,
                          datasets: [
                            {
                              label: `Leituras ${dt.departamento}`,
                              backgroundColor: [
                                '#2125a5',
                                '#e4f914',
                                '#032738',
                                '#ac3128',
                                '#bad3dd',
                                '#63c12c',
                                '#50b8c8',
                                '#e01aab',
                              ],
                              data: labelqtd,
                            },
                          ],
                        }}
                        options={{
                          plugins: {
                            legend: {
                              labels: {
                                color: '#000',
                              }
                            }
                          },
                          scales: {
                            x: {
                              grid: {
                                color: "#DDDDE0",
                              },
                              ticks: {
                                color: '#000',
                              },
                            },
                            y: {
                              grid: {
                                color: "#DDDDE0",
                              },
                              ticks: {
                                color: '#000',
                              },
                            },
                          },
                        }}
                      />
                    </div>
                  </div>

                )
              })*/
            }
          </div>
      </PageCard>
    </PageContainer>
  );
}