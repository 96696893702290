import React, {useEffect, useState} from 'react';
import Alert from "../../../helpers/Alert";
import lojaApi from '../../../services/lojaApi';
import PageContainer from '../../../components/container/PageContainer';
import Title from '../../../components/text/Title';
import PageCard from '../../../components/card/PageCard';
import Select from '../../../components/select/Select';
import Input from '../../../components/input/Input';
import Button from '../../../components/button/Button';
import localizacaoApi from '../../../services/localizacaoApi';
import dashboardApi from '../../../services/dashboardApi';
import {CChart} from '@coreui/react-chartjs';
import Str from '../../../helpers/Str';
import './dueDate.css';
export default function DueDate() {
  const [datesBetween, setDatesBetween] = useState<any>([]);
  const [searchData, setSearchData] = useState<any>({
    'data_inicio': new Date().toISOString().split("T")[0],
    'data_fim': new Date().toISOString().split("T")[0],
  });
  const [selectStore, setSelectStore] = useState([]);
  const [searchDataSelect, setSearchDataSelect] = useState<any>('');

  const [selectLocalizacao, setSelectLocalizacao] = useState<any>([]);
  const [searchDataLocalizacao, setSearchDataLocalizacao] = useState<any>(['Todos']);
  const [tipoVisualizacao, setTipoVisualizacao] = useState<any>('quantidade');
  const [tipoVisualizacaoValidate, setTipoVisualizacaoValidate] = useState<any>('quantidade');

  const [searchLoad, setSearchLoad] = useState(false);

  const [data, setData] = useState<any>([]);

  const [graphData, setGraphData] = useState<any>();

  async function loadData() {
    Alert.await(`Carregando dados...`);
    const {data, isError} = await lojaApi.listAll();
    if (isError) return alert(data.message || 'Falha ao carregar dados da loja.');
    let dtSelect = data.map((res: any) => ({label:res.razao_social, key: res.id}));
    dtSelect.unshift({label:'Todos', key: ''});
    setSelectStore(dtSelect as any);

    const dataLocalization = await localizacaoApi.listAll();
    if (dataLocalization.isError) return alert(dataLocalization.data.message || 'Falha ao carregar dadas localizações.');
    let dtlocSelect = dataLocalization.data.map((res: any) => ({label:res.descricao, key: res.descricao}));
    dtlocSelect.unshift({label:'Todos', key: 'Todos'});
    setSelectLocalizacao(dtlocSelect as any);
    Alert.close();
  }

  useEffect(() => {
    loadData();
  }, []);


  const handleClick = async () => {
    setSearchLoad(true);
    if (!searchData.data_inicio) {
      setSearchLoad(false);
      return Alert.error('É necessário selecionar a data de início');
    }
    if (!searchData.data_fim) {
      setSearchLoad(false);
      return Alert.error('É necessário selecionar a data final');
    }

    let dataLocalizacao: any[] = [];
    if (searchDataLocalizacao){
      dataLocalizacao = [...searchDataLocalizacao]
      var indice = dataLocalizacao.indexOf("Todos");
      dataLocalizacao.splice(indice, 1)
    }

    const {data, isError} = await dashboardApi.dashboardVencimentoCount(
      searchDataSelect,
      searchData.data_inicio,
      searchData.data_fim,
      dataLocalizacao
    );
    setTipoVisualizacaoValidate(tipoVisualizacao)
    let date1 = new Date(searchData.data_inicio);
    let date2= new Date(searchData.data_fim);
    date1.setDate(date1.getDate()+1);
    date2.setDate(date2.getDate()+1);
    setDatesBetween(Str.getDatesBetween(date1, date2));

    setData(data);

    const dataGroup = Str.arrayGroupBy(data, 'validade');

    let dataGraph: { data: any; naoVencido: any; vencido: any; }[] = [];
    let somaNaoVendicos = 0;
    let somaVencidos = 0;
    Object.keys(dataGroup).forEach((key, index) => {
      //console.log(key);
      dataGroup[key].forEach(dtg => {
        let contVencidos = 0;
        let contNaoVencidos = 0;

        if (dtg.vencido){
          contVencidos += dtg.quantidade;
          somaVencidos += dtg.quantidade;
        }else{
          contNaoVencidos += dtg.quantidade;
          somaNaoVendicos += dtg.quantidade;
        }

        const gdIndex = dataGraph.findIndex(gdfi => gdfi.data === dtg.created_at);
        if(gdIndex >= 0){
          dataGraph[gdIndex].naoVencido += contNaoVencidos;
          dataGraph[gdIndex].vencido += contVencidos
          return
        }
        dataGraph.push({
          data: dtg.created_at,
          naoVencido: contNaoVencidos,
          vencido: contVencidos,
        })
      })
    });
    dataGraph.push({
      data: 'Total',
      naoVencido: somaNaoVendicos,
      vencido: somaVencidos,
    })

    if(tipoVisualizacao === 'percentual'){
     dataGraph.map((dg, index) => {

       const percentVencidos = dataGraph[index].vencido;
       const percentNaoVencidos = dataGraph[index].naoVencido;

       let percentualGraficVencidos = parseFloat(String(percentVencidos * 100 / (percentVencidos + percentNaoVencidos))).toFixed(2);
       let percentualGraficNaoVencidos = parseFloat(String(percentNaoVencidos * 100 / (percentVencidos + percentNaoVencidos))).toFixed(2);

       dataGraph[index].vencido = percentualGraficVencidos;
       dataGraph[index].naoVencido = percentualGraficNaoVencidos;

      });
    }

    const labels = Object.keys(Str.arrayGroupBy(data, 'created_at')).map(lb => Str.convertDate(lb));
    dataGraph = Str.arrayGroupBy(dataGraph, 'data');
    labels.push('Total');

    let vencidos: any[] = [];
    let naoVendicos: any[] = [];
    Object.keys(dataGraph).map((key, index) =>{
      const gd = dataGraph[key];
      vencidos.push(gd[0].vencido);
      naoVendicos.push(gd[0].naoVencido);
    });

    const finalDt = {
      labels,
      datasets: [
        {
          label: 'Não vencidos',
          backgroundColor: '#2125a5',
          data: naoVendicos,
        },
        {
          label: 'Vencidos',
          backgroundColor: '#ac3128',
          data: vencidos,
        }
      ]
    }
    setGraphData(finalDt)
    setSearchLoad(false);
  }

  const getTotalValues = (value) => {
    if (tipoVisualizacaoValidate === 'percentual'){
      return(value+"%");
    }
    return(value);
  }
  let totalGeralVencidos: number = 0;
  let totalGeralNaoVencidos: number = 0;
  let totalGeral: number = 0;
  return(
    <PageContainer menuTitle="Contagem de vencimento" pageTitle="Contagem de vencimento">
      <PageCard
        header={
          <div className="flex items-center justify-between">
            <Title color="primary">Contagem de vencimento</Title>
          </div>
        }
      >
        <div className="grid grid-cols-12 gap-5 pb-7">
          <Select
            label="Seleciona loja"
            className="col-span-2"
            value={searchDataSelect}
            setValue={v => setSearchDataSelect(v)}
            items={selectStore}
          />
          <Select
            multiple={true}
            label="Seleciona localização"
            className="col-span-2"
            value={searchDataLocalizacao}
            setValue={v => setSearchDataLocalizacao(v)}
            items={selectLocalizacao}
            disabled={!searchDataSelect}
          />

          <Select
            label="Tipo de informação"
            className="col-span-2"
            value={tipoVisualizacao}
            setValue={v => setTipoVisualizacao(v)}
            items={[
              { label: 'Quantidade', key: 'quantidade' },
              { label: 'Percentual', key: 'percentual' },
            ]}
          />

          <Input
            label="Data inicial"
            type={"date"}
            className="col-span-2"
            value={searchData.data_inicio}
            setValue={(v) => setSearchData({...searchData, ['data_inicio']: v})}
          />
          <Input
            label="Data final"
            type={"date"}
            className="col-span-2"
            value={searchData.data_fim}
            setValue={(v) => setSearchData({...searchData, ['data_fim']: v})}
          />

          <Button
            className="min-w-[147px] h-[37.5px] mt-[1.5rem]"
            onClick={() => handleClick()}
            loading={searchLoad}
          >
            Buscar dados
          </Button>
        </div>
        <div className={'flex flex-row'}>
          <div className={'pb-5 overflow-auto tableContainer w-[50%]'}>
            {
              data.length ?
                <table className="table-auto w-[100%]">
                  <thead className="bg-light border-b">
                  <tr className={'border-t'}>
                    <th className={'text-start py-3 px-5'}>Data</th>
                    <th className={'text-start py-3 px-5'}>Sim</th>
                    <th className={'text-start py-3 px-5'}>Não</th>
                    <th className={'text-start py-3 px-5'}>Total</th>
                  </tr>
                  </thead>
                  <tbody>
                  {
                    datesBetween && datesBetween.map((date: any)=>{
                      let qtdVencido = 0;
                      let qtdNaoVencido = 0;
                      let qtdTotal = 0;

                      data.map((st: any) =>{
                        if (Str.convertDate(st.created_at) === date){
                          if (st.vencido){
                            qtdVencido += st.quantidade
                            totalGeralVencidos += st.quantidade
                          }else{
                            qtdNaoVencido += st.quantidade
                            totalGeralNaoVencidos += st.quantidade
                          }
                          qtdTotal += st.quantidade
                          totalGeral += st.quantidade
                        }
                      });

                      let percentualVencidos: any = parseFloat(String(qtdVencido * 100 / (qtdVencido + qtdNaoVencido))).toFixed(2);
                      percentualVencidos = percentualVencidos === 'NaN' ? parseFloat(String(0.00)).toFixed(2) : percentualVencidos;

                      let percentualNaoVencidos: any = parseFloat(String(qtdNaoVencido * 100 / (qtdVencido + qtdNaoVencido))).toFixed(2);
                      percentualNaoVencidos = percentualNaoVencidos === 'NaN' ? parseFloat(String(0.00)).toFixed(2) : percentualNaoVencidos

                      let percentualQtdTotal = parseFloat(String(qtdVencido * 100 / (qtdVencido + qtdNaoVencido) + qtdNaoVencido * 100 / (qtdVencido + qtdNaoVencido))).toFixed(2);
                      percentualQtdTotal = percentualQtdTotal === 'NaN' ? parseFloat(String(0.00)).toFixed(2) : percentualQtdTotal;
                      const valorFinalQtdVencido = tipoVisualizacaoValidate === 'quantidade' ? qtdVencido : percentualVencidos+'%'
                      const valorFinalQtdNaoVencido = tipoVisualizacaoValidate === 'quantidade' ? qtdNaoVencido :  percentualNaoVencidos+'%'
                      const valorFinalQtdTotal = tipoVisualizacaoValidate === 'quantidade' ? qtdTotal :  percentualQtdTotal+'%'

                      return(
                        <tr className={'border-t'}>
                          <td className={'text-start py-3 px-5'}>{date}</td>
                          <td className={'text-start py-3 px-5'}>{valorFinalQtdVencido}</td>
                          <td className={'text-start py-3 px-5'}>{valorFinalQtdNaoVencido}</td>
                          <td className={'text-start py-3 px-5'}>{valorFinalQtdTotal}</td>
                        </tr>
                      )
                    })}
                  {
                    tipoVisualizacaoValidate === 'quantidade' ?
                      <tr className={'border-t'}>
                        <td className={'text-start py-3 px-5 font-bold'}>Total Geral</td>
                        <td className={'text-start py-3 px-5 font-bold'}>{totalGeralVencidos}</td>
                        <td className={'text-start py-3 px-5 font-bold'}>{totalGeralNaoVencidos}</td>
                        <td className={'text-start py-3 px-5 font-bold'}>{totalGeral}</td>
                      </tr>
                      :
                      <tr className={'border-t'}>
                      <td className={'text-start py-3 px-5 font-bold'}>Total Geral</td>
                      <td className={'text-start py-3 px-5 font-bold'}>{
                        getTotalValues(parseFloat(String(totalGeralVencidos * 100 / (totalGeralVencidos + totalGeralNaoVencidos))).toFixed(2))
                      }</td>
                      <td className={'text-start py-3 px-5 font-bold'}>{getTotalValues(parseFloat(String(totalGeralNaoVencidos * 100 / (totalGeralVencidos + totalGeralNaoVencidos))).toFixed(2))}</td>
                      <td className={'text-start py-3 px-5 font-bold'}>
                        {
                          getTotalValues(
                            parseFloat(String(
                              totalGeralVencidos * 100 / (totalGeralVencidos + totalGeralNaoVencidos)
                              +
                              totalGeralNaoVencidos * 100 / (totalGeralVencidos + totalGeralNaoVencidos))
                            ).toFixed(2))
                        }
                      </td>
                    </tr>
                  }


                  </tbody>
                </table>
                :
                ""
            }

          </div>
          <div className={'pb-5 w-[50%]'}>
            {
             (data.length && graphData) ? <CChart
                type="bar"
                data={graphData}
                options={{
                  plugins: {
                    legend: {
                      labels: {
                        color: '#000',
                      }
                    }
                  },
                  scales: {
                    x: {
                      grid: {
                        color: "#DDDDE0",
                      },
                      ticks: {
                        color: '#000',
                      },
                    },
                    y: {
                      grid: {
                        color: "#DDDDE0",
                      },
                      ticks: {
                        color: '#000',
                      },
                    },
                  },
                }}
              /> : ''
            }
          </div>
        </div>

      </PageCard>
    </PageContainer>
  );

}