import React, { useState }  from 'react';

import { TipoLeitura } from '../../../types';

import SearchPageCard from '../../../components/card/SearchPageCard';
import PageContainer from '../../../components/container/PageContainer';
import Table from '../../../components/table/Table';
import PageCard from '../../../components/card/PageCard';
import Title from '../../../components/text/Title';
import Button from '../../../components/button/Button';
import Checkbox from '../../../components/checkbox/Checkbox';
import useTable from '../../../hooks/useTable';
import tipoLeituraApi from '../../../services/tipoLeituraApi';
import Input from '../../../components/input/Input';
import Form from '../../../components/form/Form';
import useForm from '../../../hooks/useForm';
import Select from '../../../components/select/Select';
import Alert from '../../../helpers/Alert';

export default function SurveyType() {
  const [isLoading, setIsLoading] = useState(false);
  const { form, setForm, handleChange } = useForm<TipoLeitura>({
    initialData: {
      bloqueado: false,
    }
  });

  const { tableProps, onSearch, loadData } = useTable<TipoLeitura>({
    dataLoader: async (params) => {
      const { data, isError } = await tipoLeituraApi.all(params);
      return isError ? false : data;
    },
    onItemClick: setForm,
  });

  async function handleSubmit() {
    setIsLoading(true);
    const { data, isError } = form.id
      ? await tipoLeituraApi.update(form.id, form)
      : await tipoLeituraApi.create(form);
    setIsLoading(false);

    if (isError) return Alert.error(data.message ? data.message : data);
    loadData();
    setForm({ bloqueado: false } as TipoLeitura);
  }

  async function handleDelete() {
    if (! form.id) return;
    const confirm = await Alert.confirm(
      'Tem certeza que deseja excluir?',
      'Excluir Tipo de Pesquisa?'
    );
    if (! confirm) return;
    setIsLoading(true);
    const { data, isError } = await tipoLeituraApi.destroy(form.id);
    setIsLoading(false);
    if (isError) return alert(data.message);
    loadData();
    setForm({ bloqueado: false } as TipoLeitura);
  }

  return (
    <PageContainer menuTitle="Tipos de Pesquisa">
      <div className="grid grid-cols-3 gap-2">
        <div className="flex flex-col gap-2 col-span-1">
          <SearchPageCard 
            fullWidthInput
            onSearch={onSearch}
            placeholder="Buscar tipos de pesquisa"
          />
          <Table 
            {...tableProps}
            columns={[
              { title: 'Perfil', field: 'descricao' },
              { title: 'Status', field: 'bloqueado', columnType: 'reversedStatus' },
            ]} 
          />
        </div>

        <PageCard
          className="col-span-2" 
          header={<Title color="primary">Cadastro de Tipo de Pesquisa</Title>}
          footer={<div className="flex items-center gap-5">
            {!!form.id && <>
              <Button loading={isLoading} onClick={() => setForm({ bloqueado: false } as TipoLeitura)}>
                Novo
              </Button>
              <Button loading={isLoading} onClick={handleDelete}>
                Excluir
              </Button>
            </>}
            <Button loading={isLoading} onClick={handleSubmit}>
              Salvar
            </Button>
          </div>}
        >
          <Form onSubmit={handleSubmit}>
            <header className="grid grid-cols-3 gap-5 border-b pb-7">
              <Input 
                label="Descrição" 
                className="col-span-2"
                value={form.descricao}
                setValue={(v) => handleChange(v, 'descricao')}
              />
              <Select 
                label="Status" 
                value={form.bloqueado}
                setValue={(v) => handleChange(v, 'bloqueado')}
                items={[
                  { label: 'Ativo', key: false },
                  { label: 'Inativo', key: true },
                ]}
              />
            </header>

            <div className='mt-5'>
              Campos disponíveis para pesquisa
            </div>
            <div className="border rounded-md mt-2 p-5 grid gap-5">
              {/* <Checkbox label="Descrição" /> */}
              {/* <Checkbox label="Tipo" /> */}

              <Checkbox 
                label="Quantidade"
                value={!!form.quantidade}
                setValue={(v) => handleChange(v, 'quantidade')}
              />

              <Checkbox 
                label="Data de Validade"
                value={!!form.validade}
                setValue={(v) => handleChange(v, 'validade')}
              />

              <Checkbox 
                label="Preço Varejo"
                value={!!form.preco_varejo}
                setValue={(v) => handleChange(v, 'preco_varejo')}
              />

              <Checkbox 
                label="Preço Atacado"
                value={!!form.preco_atacado}
                setValue={(v) => handleChange(v, 'preco_atacado')}
              />

              <Checkbox 
                label="Número do Lote"
                value={!!form.lote}
                setValue={(v) => handleChange(v, 'lote')}
              />

              <Checkbox 
                label="Fotos"
                value={!!form.fotos}
                setValue={(v) => handleChange(v, 'fotos')}
              />
            </div>
          </Form>
        </PageCard>
      </div>
    </PageContainer>
  );
}
